import Papa from 'papaparse';

const getString = (data, headers) => {
  const fields = Object.keys(headers || data[0]);
  const head = fields.map((key) => getColumnTitle(headers, key)).join(',') + '\n';
  const csv = data.map((datum) => fields.map((key) => getCellValue(headers, datum, key)).join(',')).join('\n');
  return head + csv;
};

const getColumnTitle = (headers, key) => headers?.[key]?.title || headers?.[key] || key;

function getCellValue(headers, datum, key) {
  const value = headers?.[key]?.getValue ? headers?.[key]?.getValue(datum) : datum[key] ?? '';
  return String(value).includes(',') ? `"${value}"` : String(value);
}

function parseCsvFile(csvFile) {
  return new Promise((resolve) => {
    Papa.parse(csvFile, {
      header: true,
      skipEmptyLines: true,
      complete: function (results) {
        resolve(results.data);
      },
    });
  });
}

const CsvHelper = { getString, parseCsvFile };
export default CsvHelper;
