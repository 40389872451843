import React, { useCallback, useContext, useEffect, useState } from 'react';
import SearchIcon from '@material-ui/icons/Search';
import { Button, Tooltip } from '@material-ui/core';
import { Link } from 'react-router-dom';
import styles from './styles.module.css';
import DataTable from '../../utils/DataTable';
import StockDamageService from '../../../services/StockDamageService';
import NoteAddIcon from '@material-ui/icons/NoteAdd';
import Loader from '../../utils/Loading';
import ConfirmPopup from '../../utils/Alert/ConfirmPopup';
import Info from '../../utils/Alert/Info';
import RestrictionInfo from '../../utils/Alert/RestrictionInfo';
import withConsoleBase from '../../utils/ConsoleBase/withConsoleBase';
import ImportExportIcon from '@material-ui/icons/ImportExport';
import { ROUTES, SUBSCRIPTION_TYPE, USER_PERMISSIONS } from '../../../const';
import { UserContext } from '../../../Context/UserContext';
import { ShopContext } from '../../../Context/ShopContext';
import { AlertMessagesContext } from 'react-alert-messages';
import { KeyboardDatePicker, MuiPickersUtilsProvider } from '@material-ui/pickers';
import DateFnsUtils from '@date-io/date-fns';
import DateTimeHelpers from '../../../helpers/DateTimeHelpers';
import { saveAs } from 'file-saver';
import CsvHelper from '../../../helpers/CsvHelper';
import SyncIcon from '@mui/icons-material/Sync';
import ShopsHelper from '../../../helpers/ShopsHelper';
import Grayout from '../../utils/GrayOut/Grayout';

function StockDamageHistory() {
  const { user } = useContext(UserContext);
  const { shop } = useContext(ShopContext);
  const { postAlertMessage } = useContext(AlertMessagesContext);

  const [filterFromDate, setFilterFromDate] = useState(new Date());
  const [filterToDate, setFilterToDate] = useState(new Date());
  const [loadingIndicator, setLoadingIndicator] = useState(false);
  const [data, setData] = useState([]);
  const [searchText, setSearchText] = useState('');
  const [deleteData, setDeleteData] = useState(false);
  const [isShowGenerateButton, setIsShowGenerateButton] = useState(null);
  const [prevFilterData, setPrevFilterData] = useState({
    filterFromDate,
    filterToDate,
  });

  const CSV_COLUMNS = {
    date: 'Date',
    plu: {
      title: 'PLU',
      getValue: (item) => item?.item?.plu,
    },
    item: {
      title: 'Item Name',
      getValue: (item) => item?.item?.name,
    },
    cost: {
      title: 'Cost',
      getValue: (item) => item?.item?.cost,
    },
    quantity: 'Quantity',
    description: 'Description',
  };

  const subscriptionType = shop?.subscription?.type;
  const userHasDamageWritePermission = user?.shop_permissions?.damage_permission === USER_PERMISSIONS.WRITE;

  const handleDeleteClick = (data) => setDeleteData(data);

  const handleDltStockDamage = async (row) => {
    setLoadingIndicator(true);
    try {
      await StockDamageService.deleteStockDamageHistory(row.id);
      loadStockDamageHistory().then();

      postAlertMessage({
        text: 'Damage deleted successfully',
        type: 'success',
      });
    } catch (err) {
      postAlertMessage({ text: err.message, type: 'failed' });
    }
    setLoadingIndicator(false);
  };

  const _getLoadDamageHistoryRequestParams = useCallback(() => {
    return {
      date_start: DateTimeHelpers.convertDateToIsoDMY(filterFromDate),
      date_end: DateTimeHelpers.convertDateToIsoDMY(filterToDate),
    };
  }, [filterFromDate, filterToDate]);

  const loadStockDamageHistory = useCallback(async () => {
    setLoadingIndicator(true);
    try {
      const params = _getLoadDamageHistoryRequestParams();
      const resp = await StockDamageService.getStockDamageHistory(params);
      setData(resp);
    } catch (err) {
      postAlertMessage({ text: err.message, type: 'failed' });
    }
    setLoadingIndicator(false);
  }, [_getLoadDamageHistoryRequestParams, postAlertMessage]);

  const toCsvBtnPressed = async () => {
    const csv = CsvHelper.getString(data, CSV_COLUMNS);
    const blob = new Blob([csv], {
      type: 'text/csv',
    });
    saveAs(blob, `stock-damage-${new Date().toISOString()}.csv`);

    postAlertMessage({
      text: 'Exported to excel successfully',
      type: 'success',
    });
  };

  const generateStockDamageHistory = () => {
    loadStockDamageHistory();
    setIsShowGenerateButton(false);
    setPrevFilterData({
      filterFromDate,
      filterToDate,
    });
  };

  const isAnyChangeOnStockDamageFilters = useCallback(() => {
    return filterFromDate !== prevFilterData.filterFromDate || filterToDate !== prevFilterData.filterToDate;
  }, [filterFromDate, filterToDate, prevFilterData.filterFromDate, prevFilterData.filterToDate]);

  const _getFilteredDamages = (damages, searchKey) => {
    if (!searchKey) {
      return damages;
    }

    return damages.filter(
      (dta) =>
        dta.item.name.toLowerCase().includes(searchKey.toLowerCase()) ||
        dta.date.includes(searchKey.toLowerCase()) ||
        dta.item.plu.toString().includes(searchKey.toLowerCase())
    );
  };

  const _getDamagesSummary = (damages) => {
    let totalQuantity = 0;
    let totalAmount = 0;
    damages.forEach((damage) => {
      totalQuantity += damage.quantity;
      totalAmount += damage.quantity * damage.item.cost;
    });

    return {
      totalQuantity: ShopsHelper.getAmountFormatted(shop, totalQuantity),
      totalAmount: ShopsHelper.getAmountFormatted(shop, totalAmount),
    };
  };

  useEffect(() => {
    generateStockDamageHistory();
    //eslint-disable-next-line
  }, []);

  useEffect(() => {
    if (isShowGenerateButton === null) return;
    setIsShowGenerateButton(isAnyChangeOnStockDamageFilters());
  }, [isAnyChangeOnStockDamageFilters, isShowGenerateButton]);

  const headerData = [
    {
      label: 'Date',
      id: 'date',
      type: 'text',
    },
    {
      label: 'PLU',
      id: 'item.plu',
      type: 'text',
    },
    {
      label: 'Item Name',
      id: 'item.name',
      type: 'text',
    },
    {
      label: 'Cost',
      id: 'item.cost',
      type: 'text',
    },
    {
      label: 'Quantity',
      id: 'quantity',
      type: 'callback',
      viewRender: (item) => {
        return `${item.quantity} ${item.item.unit || ''}`;
      },
    },
    {
      label: 'Total',
      id: 'total',
      type: 'callback',
      viewRender: (item) => {
        return item.quantity * item.item.cost;
      },
    },
  ];
  if (userHasDamageWritePermission) {
    headerData.push({
      label: 'Delete',
      id: 'deleteBtn',
      type: 'button',
      title: 'Delete',
      clickHandler: handleDeleteClick,
    });
  }

  const filteredDamages = _getFilteredDamages(data, searchText);
  const summary = _getDamagesSummary(filteredDamages);
  return (
    <div className={styles.contentWrapper}>
      <Loader isOpen={loadingIndicator} />
      <ConfirmPopup
        data={deleteData}
        handleClose={handleDeleteClick}
        handleOkay={handleDltStockDamage}
        successBtnName={'Delete'}
        alertTitle={'Confirm Delete'}
        alertContent={"Deleted Stock Damage records can't be restored, do you want to delete the Stock Damage record? "}
      />
      <div className={styles.titleSec}>
        <h2 className={styles.title}>
          Stock Damage<span className={styles.menuTitle}>Management</span>
        </h2>
        {subscriptionType && subscriptionType !== SUBSCRIPTION_TYPE.BASIC && (
          <div style={{ justifyContent: 'flex-end' }}>
            <div style={{ paddingBottom: '4px' }}>
              <label className={styles.label}>Export As</label>
            </div>
            <Button
              variant="contained"
              color="primary"
              className={styles.actionBtn}
              style={{ backgroundColor: '#00a65a', marginRight: 5 }}
              onClick={toCsvBtnPressed}
            >
              <ImportExportIcon className={styles.actionBtnIcon} />
              Csv
            </Button>
          </div>
        )}
      </div>

      {subscriptionType && subscriptionType === SUBSCRIPTION_TYPE.BASIC && (
        <RestrictionInfo
          title={'Feature not available '}
          content={'To get stock damage options upgrade subscription to premium'}
        />
      )}
      {subscriptionType && subscriptionType !== SUBSCRIPTION_TYPE.BASIC && (
        <>
          <div className={styles.changeable}>
            <div className={styles.filterSec}>
              <div className={styles.headTitle}>
                <h2 className={styles.subTitle}>Damage History</h2>
              </div>
              <div className={styles.filerInputSec}>
                {userHasDamageWritePermission && (
                  <Link to={ROUTES.NEW_DAMAGE} style={{ textDecorationColor: 'transparent' }}>
                    <Button
                      variant="contained"
                      color="primary"
                      size="small"
                      className={styles.actionBtn}
                      style={{ backgroundColor: '#ff851b' }}
                    >
                      <NoteAddIcon className={styles.actionBtnIcon} />
                      New Damage
                    </Button>
                  </Link>
                )}
                <div className={styles.searchSec}>
                  <input
                    type="text"
                    value={searchText}
                    onChange={(e) => {
                      setSearchText(e.target.value);
                    }}
                    className={styles.searchInput}
                    placeholder="search items"
                  />
                  <Tooltip title="Search" placement="bottom">
                    <SearchIcon className={styles.searchIcon} />
                  </Tooltip>
                </div>
              </div>
            </div>
            <div className={styles.actionButtons}>
              <div className={styles.filterDiv}>
                <div style={{ paddingBottom: '4px' }}>
                  <label className={styles.label}>From</label>
                </div>
                <div>
                  <MuiPickersUtilsProvider utils={DateFnsUtils}>
                    <KeyboardDatePicker
                      disableFuture
                      variant="outlined"
                      format="dd/MM/yyyy"
                      id="date-picker-from"
                      className={styles.dateBox}
                      value={filterFromDate}
                      onChange={(date) => {
                        setFilterFromDate(date);
                      }}
                      KeyboardButtonProps={{
                        'aria-label': 'Change date',
                      }}
                    />
                  </MuiPickersUtilsProvider>
                </div>
              </div>
              <div className={styles.filterDiv}>
                <div style={{ paddingBottom: '4px' }}>
                  <label className={styles.label}>To</label>
                </div>
                <div>
                  <MuiPickersUtilsProvider utils={DateFnsUtils}>
                    <KeyboardDatePicker
                      disableFuture
                      variant="outlined"
                      format="dd/MM/yyyy"
                      id="date-picker-to"
                      className={styles.dateBox}
                      value={filterToDate}
                      onChange={(date) => {
                        setFilterToDate(date);
                      }}
                      KeyboardButtonProps={{
                        'aria-label': 'Change date',
                      }}
                    />
                  </MuiPickersUtilsProvider>
                </div>
              </div>
              <div className={styles.filterDiv}>
                <div style={{ paddingBottom: '4px' }}>
                  <label className={styles.label}>Generate Report</label>
                </div>
                <div>
                  <Button
                    variant="contained"
                    color="primary"
                    className={styles.actionBtn}
                    style={{ backgroundColor: '#00a65a' }}
                    onClick={generateStockDamageHistory}
                    disabled={isShowGenerateButton === false}
                  >
                    <SyncIcon className={styles.actionBtnIcon} />
                    Generate
                  </Button>
                </div>
              </div>
            </div>
          </div>
          {isShowGenerateButton === null ? (
            <Info
              title={'Set filters and click generate button'}
              content={
                'Stock damage history are generated based on the filters. Please set filters and click generate button to get Stock damage history.'
              }
            />
          ) : (
            data &&
            (filteredDamages && filteredDamages.length ? (
              <div className={styles.tableContainer}>
                <Grayout open={isShowGenerateButton} />
                <DataTable
                  columns={headerData}
                  rows={filteredDamages}
                  summary={{
                    'item.name': 'Total',
                    quantity: summary.totalQuantity,
                    total: summary.totalAmount,
                  }}
                />
              </div>
            ) : (
              !loadingIndicator && (
                <Info
                  title={'You have no stock damages to list'}
                  content={"You haven't any damages to list with given properties"}
                />
              )
            ))
          )}
        </>
      )}
    </div>
  );
}

export default withConsoleBase(StockDamageHistory);
