import React, { useState, useContext } from 'react';
import styles from './styles.module.css';
import Loader from '../../utils/Loading';
import { AlertMessagesContext } from 'react-alert-messages';
import { useEffect } from 'react';
import DataTable from '../../utils/DataTable/index';
import Info from '../../utils/Alert/Info';
import { Button, TextField, Grid } from '@mui/material';
import OrderActionService from '../../../services/OrderActionService';
import EditOrderActionDialog from '../../popups/EditOrderActionDialog.js';

function OrderActions() {
  const { postAlertMessage } = useContext(AlertMessagesContext);
  const [loadingIndicator, setLoadingIndicator] = useState(false);
  const [orderActions, setOrderActions] = useState([]);
  const [newOrderAction, setNewOrderAction] = useState('');
  const [selectedOrderActionForEdit, setSelectedOrderActionForEdit] = useState(null);
  const [order, setOrder] = useState();

  const getOrderActions = async () => {
    setLoadingIndicator(true);
    try {
      const res = await OrderActionService.getOrderActions();
      setOrderActions(res);
    } catch (error) {
      postAlertMessage({ text: error?.message, type: 'failed' });
    }
    setLoadingIndicator(false);
  };

  const updateOrderActionStatus = (action) => {
    const data = { is_active: !action?.is_active };
    updateOrderActions(action, data);
  };

  const updateOrderActions = async (action, data) => {
    setLoadingIndicator(true);
    try {
      await OrderActionService.updateOrderAction(action?.id, data);
      postAlertMessage({
        text: 'Order actions updated successfully',
        type: 'success',
      });
      getOrderActions();
    } catch (error) {
      postAlertMessage({ text: error?.message, type: 'failed' });
    }
    setLoadingIndicator(false);
  };

  const createOrderAction = async () => {
    setLoadingIndicator(true);
    try {
      if (!newOrderAction.length) {
        postAlertMessage({
          text: 'Please provide a valid order acion name',
          type: 'failed',
        });
        return;
      }
      await OrderActionService.createOrderAction({ action: newOrderAction, order: Number(order) });
      postAlertMessage({
        text: 'Order actions added successfully',
        type: 'success',
      });
      setNewOrderAction('');
      getOrderActions();
    } catch (error) {
      postAlertMessage({ text: error?.message, type: 'failed' });
    }
    setLoadingIndicator(false);
  };

  const headerData = [
    {
      label: 'Action',
      id: 'action',
      type: 'text',
    },
    {
      label: 'Order',
      id: 'order',
      type: 'text',
    },
    {
      label: 'Status',
      id: 'staus',
      type: 'callback',
      viewRender: (obj) => {
        return obj.is_active ? 'Active' : 'Deactive';
      },
    },
    {
      label: 'Edit',
      id: 'edit',
      type: 'button',
      title: 'Edit',
      clickHandler: setSelectedOrderActionForEdit,
    },
    {
      id: 'deleteBtn',
      type: 'callback',
      align: 'right',
      viewRender: (obj) => (
        <Button
          onClick={(e) => {
            updateOrderActionStatus(obj);
            e.stopPropagation();
          }}
          variant="contained"
          size="small"
          color="primary"
          style={{ width: 105 }}
        >
          {obj.is_active ? 'Deactivate' : 'Activate'}
        </Button>
      ),
    },
  ];

  const getRowStyle = (row) => {
    if (row.is_active) {
      return;
    }
    return { backgroundColor: 'rgba(245, 169, 39, 0.2)' };
  };

  useEffect(() => {
    getOrderActions();
    // eslint-disable-next-line
  }, []);

  return (
    <div className={styles.contentLayout}>
      <Loader isOpen={loadingIndicator} />
      <div className={styles.settingsGrid}>
        <div className={styles.BillHeaderDiv}>
          <span className={styles.pageHead}>Create Order Actions</span>
        </div>
        <Grid container mb={2} columnSpacing={1}>
          <Grid item xs={4.5}>
            <TextField
              size="small"
              fullWidth
              variant="outlined"
              color="primary"
              label="Enter order action name"
              inputProps={{
                style: {
                  padding: 10,
                },
              }}
              value={newOrderAction}
              onChange={(e) => setNewOrderAction(e.target.value)}
            />
          </Grid>
          <Grid item xs={4.5} textAlign={'end'}>
            <TextField
              size="small"
              type="number"
              fullWidth
              variant="outlined"
              color="primary"
              label="Enter order"
              inputProps={{
                style: {
                  padding: 10,
                },
              }}
              value={order}
              onChange={(e) => setOrder(e.target.value)}
            />
          </Grid>
          <Grid item xs={3} textAlign={'end'}>
            <Button
              variant="contained"
              size="medium"
              style={{ backgroundColor: '#ff851b', padding: 9 }}
              onClick={createOrderAction}
            >
              Create
            </Button>
          </Grid>
        </Grid>
        {orderActions.length ? (
          <DataTable columns={headerData} rows={orderActions} rowStyle={getRowStyle} />
        ) : (
          !loadingIndicator && (
            <Info
              title={'No order actions to list'}
              content={'You have no order actions to list. Please create a new order action'}
            />
          )
        )}
      </div>
      {selectedOrderActionForEdit && (
        <EditOrderActionDialog
          handleClose={() => {
            setSelectedOrderActionForEdit(null);
          }}
          orderAction={selectedOrderActionForEdit}
          updateOrderAction={updateOrderActions}
        />
      )}
    </div>
  );
}

export default OrderActions;
