import BackendService from './BackendService';

async function getSale(id) {
  return BackendService.getData(`/sales/${id}/`);
}
async function getSales(params) {
  return BackendService.getData('/v2/sales/', {}, params);
}
async function createSale(data = {}) {
  return BackendService.postData('/sales/', data);
}
async function updateSale(id, data = {}) {
  return BackendService.patch(`/sales/${id}/`, data);
}

const SaleService = {
  getSale,
  getSales,
  createSale,
  updateSale,
};

export default SaleService;