import styles from './styles.module.css';
import React, { useEffect, useState, useContext } from 'react';
import { Grid, Button } from '@material-ui/core';
import ShopSettingsService from '../../../services/ShopSettingsService';
import { STORAGE_KEYS } from '../../../const';
import SubscriptionService from '../../../services/SubscriptionService';
import { AlertMessagesContext } from 'react-alert-messages';
import KeySignupPopup from '../../popups/KeySignupPopup';
import Loader from '../../utils/Loading';
import { ShopContext } from '../../../Context/ShopContext';

export default function ShopProfile() {
  const { postAlertMessage } = useContext(AlertMessagesContext);
  const { setShop } = useContext(ShopContext);

  const [loadingIndicator, setLoadingIndicator] = useState(false);
  const [profile, setProfile] = useState('');
  const [showKeyRenewalPopup, setShowKeyRenewalPopup] = useState(false);

  const loadShopProfile = async () => {
    setLoadingIndicator(true);
    const resp = await ShopSettingsService.loadShopProfile();
    setProfile(resp);
    setLoadingIndicator(false);
  };

  const clearAndReloadShopProfile = async () => {
    const resp = await ShopSettingsService.clearAndReloadShopProfile();
    setProfile(resp);
    setShop(resp);
  };

  const handleSubscriptionRenewalWithKey = async () => {
    setLoadingIndicator(true);
    try {
      const secret = localStorage.getItem(STORAGE_KEYS.REGISTRSATION_KEY);
      await SubscriptionService.renewSubscriptionWithKey({ secret });
      await clearAndReloadShopProfile();
      setShowKeyRenewalPopup(false);
    } catch (error) {
      postAlertMessage({ text: error.message, type: 'failed' });
    }
    setLoadingIndicator(false);
  };

  useEffect(() => {
    loadShopProfile();
  }, []);

  return (
    <>
      <Loader isOpen={loadingIndicator} />
      <div className={styles.contentLayout}>
        <div className={styles.settingsGrid}>
          <div style={{ marginBottom: 15 }}>
            <span className={styles.pageHead}>Shop details of {profile.shop_name}</span>
          </div>
          <Grid container spacing={1}>
            <Grid item xs={12} sm={12} className={styles.BillHeader}>
              <Grid item xs={12} sm={12} className={styles.fieldHead}>
                <span className={styles.fieldTitleSmall}>Shop Name</span>
              </Grid>
              <Grid item xs={12} sm={12} className={styles.fieldInput}>
                <span className={styles.fieldTitle}>{profile.shop_name}</span>
              </Grid>
            </Grid>
            <Grid item xs={12} sm={12} className={styles.BillHeader}>
              <Grid item xs={12} sm={12} className={styles.fieldHead}>
                <span className={styles.fieldTitleSmall}>Mobile No</span>
              </Grid>
              <Grid item xs={12} sm={12} className={styles.fieldInput}>
                <span className={styles.fieldTitle}>{profile.mobile_no}</span>
              </Grid>
            </Grid>
            <Grid item xs={12} sm={12} className={styles.BillHeader}>
              <Grid item xs={12} sm={12} className={styles.fieldHead}>
                <span className={styles.fieldTitleSmall}>Subscription Type</span>
              </Grid>
              <Grid item xs={12} sm={12} className={styles.fieldInput}>
                <span className={styles.fieldTitle}>{profile.subscription?.name}</span>
              </Grid>
            </Grid>
            <Grid item xs={12} sm={12} className={styles.BillHeader}>
              <Grid item xs={12} sm={12} className={styles.fieldHead}>
                <span className={styles.fieldTitleSmall}>Subscription Peroid</span>
              </Grid>
              <Grid item xs={12} sm={12} className={styles.fieldInput}>
                <span className={styles.fieldTitle}>{profile.subscription?.days} Days</span>
              </Grid>
            </Grid>
            <Grid item xs={12} sm={12} className={styles.BillHeader}>
              <Grid item xs={12} sm={12} className={styles.fieldHead}>
                <span className={styles.fieldTitleSmall}>Subscription Validity</span>
              </Grid>
              <Grid item xs={12} sm={12} className={styles.fieldInput}>
                <span className={styles.fieldTitle}>{profile.subscription_end_date}</span>
              </Grid>
            </Grid>
            {profile.image_support_validity && (
              <Grid item xs={12} sm={12} className={styles.BillHeader}>
                <Grid item xs={12} sm={12} className={styles.fieldHead}>
                  <span className={styles.fieldTitleSmall}>Image Support Validity</span>
                </Grid>
                <Grid item xs={12} sm={12} className={styles.fieldInput}>
                  <span className={styles.fieldTitle}>{profile.image_support_validity}</span>
                </Grid>
              </Grid>
            )}
            {profile.kot_validity && (
              <Grid item xs={12} sm={12} className={styles.BillHeader}>
                <Grid item xs={12} sm={12} className={styles.fieldHead}>
                  <span className={styles.fieldTitleSmall}>KOT Validity</span>
                </Grid>
                <Grid item xs={12} sm={12} className={styles.fieldInput}>
                  <span className={styles.fieldTitle}>{profile.kot_validity}</span>
                </Grid>
              </Grid>
            )}
            {profile.kot_lite_validity && (
              <Grid item xs={12} sm={12} className={styles.BillHeader}>
                <Grid item xs={12} sm={12} className={styles.fieldHead}>
                  <span className={styles.fieldTitleSmall}>KOT lite Validity</span>
                </Grid>
                <Grid item xs={12} sm={12} className={styles.fieldInput}>
                  <span className={styles.fieldTitle}>{profile.kot_lite_validity}</span>
                </Grid>
              </Grid>
            )}
            {profile.expense_validity && (
              <Grid item xs={12} sm={12} className={styles.BillHeader}>
                <Grid item xs={12} sm={12} className={styles.fieldHead}>
                  <span className={styles.fieldTitleSmall}>Additional Expense Validity</span>
                </Grid>
                <Grid item xs={12} sm={12} className={styles.fieldInput}>
                  <span className={styles.fieldTitle}>{profile.expense_validity}</span>
                </Grid>
              </Grid>
            )}
            {profile.smart_scale_validity && (
              <Grid item xs={12} sm={12} className={styles.BillHeader}>
                <Grid item xs={12} sm={12} className={styles.fieldHead}>
                  <span className={styles.fieldTitleSmall}>Smart Scale Validity</span>
                </Grid>
                <Grid item xs={12} sm={12} className={styles.fieldInput}>
                  <span className={styles.fieldTitle}>{profile.smart_scale_validity}</span>
                </Grid>
              </Grid>
            )}
            {profile.customer_support_validity && (
              <Grid item xs={12} sm={12} className={styles.BillHeader}>
                <Grid item xs={12} sm={12} className={styles.fieldHead}>
                  <span className={styles.fieldTitleSmall}>Customer Support Validity</span>
                </Grid>
                <Grid item xs={12} sm={12} className={styles.fieldInput}>
                  <span className={styles.fieldTitle}>{profile.customer_support_validity}</span>
                </Grid>
              </Grid>
            )}
            {profile.bluetooth_scale_validity && (
              <Grid item xs={12} sm={12} className={styles.BillHeader}>
                <Grid item xs={12} sm={12} className={styles.fieldHead}>
                  <span className={styles.fieldTitleSmall}>Bluetooth Support Validity</span>
                </Grid>
                <Grid item xs={12} sm={12} className={styles.fieldInput}>
                  <span className={styles.fieldTitle}>{profile.bluetooth_scale_validity}</span>
                </Grid>
              </Grid>
            )}
            {profile.quickbook_support_validity && (
              <Grid item xs={12} sm={12} className={styles.BillHeader}>
                <Grid item xs={12} sm={12} className={styles.fieldHead}>
                  <span className={styles.fieldTitleSmall}>External API Support Validity</span>
                </Grid>
                <Grid item xs={12} sm={12} className={styles.fieldInput}>
                  <span className={styles.fieldTitle}>{profile.quickbook_support_validity}</span>
                </Grid>
              </Grid>
            )}

            {/* <Grid item xs={6} sm={12} className={styles.BillHeader}>
                <Grid item xs={12} sm={12} className={styles.fieldHead}>
                  <span className={styles.fieldTitleSmall}>
                    Subscription Rate
                  </span>
                </div>
                <Grid item xs={12} sm={12} className={styles.fieldInput}>
                  <span className={styles.fieldTitle}>
                    ₹ {subscription.rate}
                  </span>
                </div>
              </Grid>
              <Grid item xs={6} sm={12} className={styles.BillHeader}>
                <Grid item xs={12} sm={12} className={styles.fieldHead}>
                  <span className={styles.fieldTitleSmall}>
                    Maximum Products
                  </span>
                </div>
                <Grid item xs={12} sm={12} className={styles.fieldInput}>
                  <span className={styles.fieldTitle}>
                    {subscription.max_products === -1
                      ? 'Unlimited Products'
                      : `${subscription.max_products} Products`}
                  </span>
                </div>
              </Grid>
              <Grid item xs={6} sm={12} className={styles.BillHeader}>
                <Grid item xs={12} sm={12} className={styles.fieldHead}>
                  <span className={styles.fieldTitleSmall}>
                    Maximum Machines
                  </span>
                </div>
                <Grid item xs={12} sm={12} className={styles.fieldInput}>
                  <span className={styles.fieldTitle}>
                    {subscription.max_machines === -1
                      ? 'Upto 5 Machines'
                      : `${subscription.max_machines} Machine`}
                  </span>
                </div>
              </Grid>
              <Grid item xs={6} sm={12} className={styles.BillHeader}>
                <Grid item xs={12} sm={12} className={styles.fieldHead}>
                  <span className={styles.fieldTitleSmall}>Product Sync</span>
                </div>
                <Grid item xs={12} sm={12} className={styles.fieldInput}>
                  <span className={styles.fieldTitle}>
                    {subscription.auto_sync_product_save === true
                      ? 'Automatic Product Sync to Machine '
                      : ` Manual Product Sync to Machine`}
                  </span>
                </div>
              </Grid>
              <Grid item xs={6} sm={12} className={styles.BillHeader}>
                <Grid item xs={12} sm={12} className={styles.fieldHead}>
                  <span className={styles.fieldTitleSmall}>Sales Sync</span>
                </div>
                <Grid item xs={12} sm={12} className={styles.fieldInput}>
                  <span className={styles.fieldTitle}>
                    {subscription.sales_sync_interval === -1
                      ? ' Automatic Sales Updates'
                      : ` Sales Update in ${subscription.sales_sync_interval} Hours`}
                  </span>
                </div>
              </Grid> */}
            <div style={{ marginBottom: 10, marginTop: 20 }}>
              <span className={styles.pageHead}>Renew Subscription</span>
            </div>
            <Grid item xs={12} sm={12} className={styles.BillHeader}>
              <Grid item xs={12} sm={12} className={styles.fieldHead}>
                <span className={styles.fieldTitleSmall}>Renew with coupon</span>
              </Grid>
              <Grid item xs={12} sm={12} className={styles.fieldInput}>
                <Button
                  variant="contained"
                  color="secondary"
                  onClick={() => {
                    setShowKeyRenewalPopup(true);
                  }}
                >
                  Renew with coupon
                </Button>
              </Grid>
            </Grid>
          </Grid>
        </div>
      </div>
      {showKeyRenewalPopup && (
        <KeySignupPopup
          handleClose={() => setShowKeyRenewalPopup(false)}
          handleKeyRegister={handleSubscriptionRenewalWithKey}
        />
      )}
    </>
  );
}
