import React, { useCallback, useContext, useEffect, useState } from 'react';
import SearchIcon from '@material-ui/icons/Search';
import { Button, Tooltip } from '@material-ui/core';
import styles from './styles.module.css';
import DataTable from '../../utils/DataTable';
import BillSummaryReportService from '../../../services/BillSummaryReportService';
import DateTimeHelpers from '../../../helpers/DateTimeHelpers';
import ImportExportIcon from '@material-ui/icons/ImportExport';
import SyncIcon from '@mui/icons-material/Sync';
import { saveAs } from 'file-saver';
import { KeyboardDatePicker, MuiPickersUtilsProvider } from '@material-ui/pickers';
import 'date-fns';
import DateFnsUtils from '@date-io/date-fns';
import Info from '../../utils/Alert/Info';
import Loader from '../../utils/Loading';
import withConsoleBase from '../../utils/ConsoleBase/withConsoleBase';
import { SUBSCRIPTION_TYPE } from '../../../const';
import { ShopContext } from '../../../Context/ShopContext';
import CsvHelper from '../../../helpers/CsvHelper';
import { AlertMessagesContext } from 'react-alert-messages';
import PrintIcon from '@mui/icons-material/Print';
import ShopsHelper from '../../../helpers/ShopsHelper';
import Grayout from '../../utils/GrayOut/Grayout';
import DateLimitDialog from '../../popups/DateLimitDialog';

function StaffSalesReport() {
  const { shop } = useContext(ShopContext);
  const { postAlertMessage } = useContext(AlertMessagesContext);

  const [loadingIndicator, setLoadingIndicator] = useState(false);
  const [filterFromDate, setFilterFromDate] = useState(new Date());
  const [filterToDate, setFilterToDate] = useState(new Date());
  const [prevFilterData, setPrevFilterData] = useState({
    filterFromDate,
    filterToDate,
  });
  const [waiterData, setWaiterData] = useState([]);
  const [cashierData, setCashierData] = useState([]);
  const [searchText, setSearchText] = useState('');
  const [filteredCashierData, setFilteredCashierData] = useState([]);
  const [filteredWaiterData, setFilteredWaiterData] = useState([]);
  const [isShowGenerateButton, setIsShowGenerateButton] = useState(null);
  const [dateLimitPopup, setDateLimitPopup] = useState(false);

  const CSV_COLUMNS = {
    name: 'Name',
    sales: 'Sales',
    discount: 'Discount',
    total: 'Total Price',
  };

  const isAnyChangeOnReportsFilters = useCallback(() => {
    return filterFromDate !== prevFilterData.filterFromDate || filterToDate !== prevFilterData.filterToDate;
  }, [filterFromDate, filterToDate, prevFilterData.filterFromDate, prevFilterData.filterToDate]);

  useEffect(() => {
    // getMachineName();
    if (subscriptionType && subscriptionType !== SUBSCRIPTION_TYPE.PREMIUM) return;
    generateSummeryReport();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    if (isShowGenerateButton === null) return;
    setIsShowGenerateButton(isAnyChangeOnReportsFilters());
  }, [isAnyChangeOnReportsFilters, isShowGenerateButton]);

  const generateSummeryReport = () => {
    loadBillSummaryReports();
    setIsShowGenerateButton(false);
    setPrevFilterData({
      filterFromDate,
      filterToDate,
    });
  };

  const toCsvBtnPressed = async () => {
    const waiterCsv = CsvHelper.getString(waiterData, CSV_COLUMNS);
    const waiterBlob = new Blob([waiterCsv], {
      type: 'text/csv',
    });
    saveAs(waiterBlob, `waiter-sales-summary-${new Date().toISOString()}.csv`);

    const cashierCsv = CsvHelper.getString(waiterData, CSV_COLUMNS);
    const cashierBlob = new Blob([cashierCsv], {
      type: 'text/csv',
    });
    saveAs(cashierBlob, `cashier-sales-summary-${new Date().toISOString()}.csv`);

    postAlertMessage({
      text: 'Exported to excel successfully',
      type: 'success',
    });
  };

  const loadBillSummaryReports = async ({ startTime = filterFromDate, endTime = filterToDate } = {}) => {
    if (DateTimeHelpers.reportDateLimit(startTime, endTime)) {
      setDateLimitPopup(true);
      return;
    }
    setLoadingIndicator(true);
    try {
      const data = {
        filter_start_time: DateTimeHelpers.startOfDay(filterFromDate),
        filter_end_time: DateTimeHelpers.endOfDay(filterToDate),
      };
      const [waiter_resp, cashier_resp] = await Promise.all([
        BillSummaryReportService.getWaiterSalesReport(data),
        BillSummaryReportService.getCashierSalesReport(data),
      ]);
      setWaiterData(waiter_resp);
      setCashierData(cashier_resp);
      setFilteredWaiterData(waiter_resp);
      setFilteredCashierData(cashier_resp);
    } catch (error) {
      postAlertMessage({ text: error.message, type: 'failed' });
    }
    setLoadingIndicator(false);
  };

  const handleSearch = (value) => {
    setSearchText(value);
    setFilteredCashierData(cashierData.filter((item) => item.name.toString().includes(value.toLowerCase())));
    setFilteredWaiterData(waiterData.filter((item) => item.name.toString().includes(value.toLowerCase())));
  };

  const subscriptionType = shop?.subscription?.type || '';
  const headerData = [
    {
      label: 'Name',
      id: 'name',
      type: 'text',
    },
    {
      label: 'Sales',
      id: 'num_bills',
      type: 'text',
    },
    {
      label: 'Discount',
      id: 'discount',
      type: 'floatAmount',
    },
    {
      label: 'Total Price',
      id: 'total',
      type: 'callback',
      viewRender: (item) => {
        return `${shop?.currency || ''} ${ShopsHelper.getAmountFormatted(shop, item.total)}`;
      },
    },
  ];

  const _getWaiterReportSummary = (items) => {
    let totalAmount = 0;
    let totalDiscount = 0;
    items.forEach((item) => {
      totalAmount += item.total;
      totalDiscount += item.discount;
    });

    return {
      totalAmount: ShopsHelper.getAmountFormatted(shop, totalAmount),
      totalDiscount: ShopsHelper.getAmountFormatted(shop, totalDiscount),
    };
  };

  const _getCashierReportSummary = (items) => {
    let totalAmount = 0;
    let totalDiscount = 0;
    items.forEach((item) => {
      totalAmount += item.total;
      totalDiscount += item.discount;
    });

    return {
      totalAmount: ShopsHelper.getAmountFormatted(shop, totalAmount),
      totalDiscount: ShopsHelper.getAmountFormatted(shop, totalDiscount),
    };
  };

  const waiterSummary = _getWaiterReportSummary(filteredWaiterData);
  const cashierSummary = _getCashierReportSummary(filteredCashierData);

  return (
    <div className={styles.contentWrapper}>
      <Loader isOpen={loadingIndicator} />
      <div className={styles.titleSec}>
        <span className={styles.title}>
          Reports<span className={styles.menuTitle}>Generation</span>
        </span>
        <div style={{ justifyContent: 'flex-end', marginRight: '10px' }}>
          <div style={{ paddingBottom: '4px' }}>
            <label className={styles.label}>Print Report</label>
          </div>
          <Button
            variant="contained"
            color="primary"
            className={styles.actionBtn}
            style={{ backgroundColor: '#00a65a' }}
            onClick={window.print}
          >
            <PrintIcon className={styles.actionBtnIcon} />
            Print
          </Button>
        </div>
        <div style={{ justifyContent: 'flex-end' }}>
          <div style={{ paddingBottom: '4px' }}>
            <label className={styles.label}>Export As</label>
          </div>
          <Button
            variant="contained"
            color="primary"
            className={styles.actionBtn}
            style={{ backgroundColor: '#00a65a' }}
            onClick={toCsvBtnPressed}
          >
            <ImportExportIcon className={styles.actionBtnIcon} />
            CSV
          </Button>
        </div>
      </div>
      <div className={styles.changeable}>
        <div className={styles.filterSec}>
          <div className={styles.headTitle}>
            <h2 className={styles.subTitle}>
              Staff Sales Report for {DateTimeHelpers.convertDateToDMY(filterFromDate)} to{' '}
              {DateTimeHelpers.convertDateToDMY(filterToDate)}
            </h2>
          </div>
          <div className={styles.filerInputSec}>
            <div className={styles.searchSec}>
              <input
                type="text"
                value={searchText}
                onChange={(e) => {
                  handleSearch(e.target.value);
                }}
                className={styles.searchInput}
                placeholder="Search by name"
              />
              <Tooltip title="Search" placement="bottom">
                <SearchIcon className={styles.searchIcon} />
              </Tooltip>
            </div>
          </div>
        </div>
        <div className={styles.actionButtons}>
          <div className={styles.filterDiv}>
            <div style={{ paddingBottom: '4px' }}>
              <label className={styles.label}>From</label>
            </div>
            <div>
              <MuiPickersUtilsProvider utils={DateFnsUtils}>
                <KeyboardDatePicker
                  disableFuture
                  format="dd/MM/yyyy"
                  id="date-picker-from"
                  className={styles.dateBox}
                  value={filterFromDate}
                  onChange={(date) => {
                    setFilterFromDate(date);
                  }}
                  KeyboardButtonProps={{
                    'aria-label': 'Change date',
                  }}
                />
              </MuiPickersUtilsProvider>
            </div>
          </div>
          <div className={styles.filterDiv}>
            <div style={{ paddingBottom: '4px' }}>
              <label className={styles.label}>To</label>
            </div>
            <div>
              <MuiPickersUtilsProvider utils={DateFnsUtils}>
                <KeyboardDatePicker
                  disableFuture
                  format="dd/MM/yyyy"
                  id="date-picker-to"
                  className={styles.dateBox}
                  value={filterToDate}
                  onChange={(date) => {
                    setFilterToDate(date);
                  }}
                  KeyboardButtonProps={{
                    'aria-label': 'Change date',
                  }}
                />
              </MuiPickersUtilsProvider>
            </div>
          </div>
          <div className={styles.filterDiv}>
            <div style={{ paddingBottom: '4px' }}>
              <label className={styles.label}>Generate Report</label>
            </div>
            <div>
              <Button
                variant="contained"
                color="primary"
                className={styles.actionBtn}
                style={{ backgroundColor: '#00a65a' }}
                onClick={generateSummeryReport}
                disabled={isShowGenerateButton === false}
              >
                <SyncIcon className={styles.actionBtnIcon} />
                Generate
              </Button>
            </div>
          </div>
        </div>
      </div>
      {isShowGenerateButton === null ? (
        <Info
          title={'Set filters and click generate button'}
          content={
            'Reports are generated based on the filters. Please set filters and click generate button to generate the report.'
          }
        />
      ) : (
        <>
          {cashierData &&
            (filteredCashierData?.length > 0 || !loadingIndicator) &&
            (filteredCashierData?.length > 0 ? (
              <>
                <h2 className={styles.subTitle} style={{ padding: '20px 5px' }}>
                  Cashier Sales Report
                </h2>
                <div className={styles.tableContainer}>
                  <Grayout open={isShowGenerateButton} />
                  <DataTable
                    columns={headerData}
                    rows={filteredCashierData || cashierData}
                    rowKey="id"
                    summary={{
                      name: 'Total',
                      discount: `${shop?.currency || ''}  ${cashierSummary.totalDiscount}`,
                      total: `${shop?.currency} ${cashierSummary.totalAmount}`,
                    }}
                  />
                </div>
              </>
            ) : (
              <Info
                severity="warning"
                title={'Oh no, there are no sales matching to your filter'}
                content={
                  'There are no sales matching to your filter. Please verify your inputs to generate the report.'
                }
              />
            ))}

          {waiterData &&
            (filteredWaiterData?.length > 0 || !loadingIndicator) &&
            (filteredWaiterData?.length > 0 ? (
              <>
                <h2 className={styles.subTitle} style={{ padding: '20px 5px' }}>
                  Waiter Sales Report
                </h2>
                <div className={styles.tableContainer}>
                  <Grayout open={isShowGenerateButton} />
                  <DataTable
                    columns={headerData}
                    rows={filteredWaiterData || waiterData}
                    rowKey="id"
                    summary={{
                      name: 'Total',
                      total_discount: `${shop?.currency || ''}  ${waiterSummary.totalDiscount}`,
                      total_amount: `${shop?.currency} ${waiterSummary.totalAmount}`,
                    }}
                  />
                </div>
              </>
            ) : (
              <Info
                severity="warning"
                title={'Oh no, there are no sales matching to your filter'}
                content={
                  'There are no sales matching to your filter. Please verify your inputs to generate the report.'
                }
              />
            ))}
        </>
      )}
      {dateLimitPopup && (
        <DateLimitDialog
          handleClose={() => {
            setDateLimitPopup(false);
          }}
        />
      )}
    </div>
  );
}

export default withConsoleBase(StaffSalesReport);
