import styles from './styles.module.css';
import CloseIcon from '@material-ui/icons/Close';
import DataTable from '../utils/DataTable';
import Info from '../utils/Alert/Info';
import React, { useContext } from 'react';
import GetAppIcon from '@mui/icons-material/GetApp';
import CsvHelper from '../../helpers/CsvHelper';
import { saveAs } from 'file-saver';
import { AlertMessagesContext } from 'react-alert-messages';

export default function ListDialog({ toggleItemsTab, rowData, tableHeader, title, emptyDataContent, csvColumns }) {
  const { postAlertMessage } = useContext(AlertMessagesContext);

  const getUpdatedData = (data) => {
    return data.map((item) => {
      return {
        ...item,
        inbound: item.quantity > 0 ? item.quantity : null,
        outbound: item.quantity < 0 ? Math.abs(item.quantity) : null,
      };
    });
  };

  const toCsvBtnPressed = async () => {
    if (!rowData.length) return;
    const csv = CsvHelper.getString(getUpdatedData(rowData), csvColumns);
    const blob = new Blob([csv], {
      type: 'text/csv',
    });
    saveAs(blob, `export-${new Date().toISOString()}.csv`);

    postAlertMessage({
      text: 'Exported to csv successfully',
      type: 'success',
    });
  };

  return (
    <div className={styles.popUp}>
      <div className={styles.contentWrapper}>
        <div className={styles.headSec}>
          <h2 className={styles.editTitle}>{title}</h2>
          <div>
            <GetAppIcon className={styles.icon} style={{ marginRight: 15 }} onClick={toCsvBtnPressed} />
            <CloseIcon
              style={{ cursor: 'pointer' }}
              onClick={() => {
                toggleItemsTab('');
              }}
            />
          </div>
        </div>
        <div className={styles.tableContent}>
          {rowData.length && rowData ? (
            <DataTable columns={tableHeader} rows={rowData} />
          ) : (
            <Info title={title} content={emptyDataContent} />
          )}
        </div>
      </div>
    </div>
  );
}
