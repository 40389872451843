import React, { useCallback, useContext, useEffect, useState } from 'react';
import SearchIcon from '@material-ui/icons/Search';
import {
  Button,
  MenuItem,
  ListItemText,
  OutlinedInput,
  Checkbox,
  Select,
  FormControl,
  Tooltip,
} from '@material-ui/core';
import styles from './styles.module.css';
import DataTable from '../../utils/DataTable';
import BillSummaryReportService from '../../../services/BillSummaryReportService';
import SalespersonsService from '../../../services/SalesPersonService';
import DateTimeHelpers from '../../../helpers/DateTimeHelpers';
import ImportExportIcon from '@material-ui/icons/ImportExport';
import SyncIcon from '@mui/icons-material/Sync';
import { saveAs } from 'file-saver';
import { KeyboardDatePicker, KeyboardTimePicker, MuiPickersUtilsProvider } from '@material-ui/pickers';
import 'date-fns';
import DateFnsUtils from '@date-io/date-fns';
import Info from '../../utils/Alert/Info';
import DateLimitDialog from '../../popups/DateLimitDialog';
import Loader from '../../utils/Loading';
import withConsoleBase from '../../utils/ConsoleBase/withConsoleBase';
import { STORAGE_KEYS, SelectFilterStyle } from '../../../const';
import { ShopContext } from '../../../Context/ShopContext';
import CsvHelper from '../../../helpers/CsvHelper';
import { AlertMessagesContext } from 'react-alert-messages';
import { filterEndDate } from '../../utils/FilterEndDate';
import PrintIcon from '@mui/icons-material/Print';
import ShopsHelper from '../../../helpers/ShopsHelper';
import Grayout from '../../utils/GrayOut/Grayout';
import TerminalService from '../../../services/TerminalServices';
import { getFilteredCSVColumns } from '../../utils/FilteredColumns';

function SalesTaxSummaryReport() {
  const { shop } = useContext(ShopContext);
  const { postAlertMessage } = useContext(AlertMessagesContext);

  const [loadingIndicator, setLoadingIndicator] = useState(false);
  const [filterFromDate, setFilterFromDate] = useState(getDefaultStartTime());
  const [filterToDate, setFilterToDate] = useState(filterEndDate());
  const [prevFilterData, setPrevFilterData] = useState({
    filterFromDate,
    filterToDate,
  });
  const [terminals, setTerminals] = useState([]);
  const [filterTerminal, setFilterTerminal] = useState([]);
  const [salesPersons, setSalesPersons] = useState([]);
  const [filterSalesperson, setFilterSalesperson] = useState([]);
  const [items, setItems] = useState([]);
  const [searchText, setSearchText] = useState('');
  const [filteredItems, setFilteredItems] = useState([]);
  const [isShowGenerateButton, setIsShowGenerateButton] = useState(null);
  const [dateLimitPopup, setDateLimitPopup] = useState(false);

  const CSV_COLUMNS = {
    bill_no: {
      title: 'Bill No',
      getValue: (datum) => datum.bill_no || datum.fid,
    },
    date: 'Date',
    time: 'Time',
    customer_name: 'Customer',
    customer_mobile: 'Mobile',
    customer_tax_id: 'Tax ID',
    discount: {
      title: 'Discount',
      getValue: (datum) => datum.discount + datum.discount_applied_on_items,
    },
    net_amount: {
      title: 'Net Amount',
      getValue: (datum) =>
        ShopsHelper.getAmountFormatted(
          shop,
          datum.total - datum.tax_amount + (datum.discount + datum.discount_applied_on_items)
        ),
    },
    tax_amount: 'Tax Amount',
    cess_amount: 'Additional Tax',
    total: 'Total Price',
  };

  function getDefaultStartTime() {
    const date = new Date();
    date.setHours(0, 0, 0, 0);
    return date;
  }

  const isAnyChangeOnReportsFilters = useCallback(() => {
    return (
      filterFromDate !== prevFilterData.filterFromDate ||
      filterToDate !== prevFilterData.filterToDate ||
      filterTerminal !== prevFilterData.filterTerminal ||
      filterSalesperson !== prevFilterData.filterSalesperson
    );
  }, [
    filterFromDate,
    filterToDate,
    filterTerminal,
    filterSalesperson,
    prevFilterData.filterFromDate,
    prevFilterData.filterToDate,
    prevFilterData.filterTerminal,
    prevFilterData.filterSalesperson,
  ]);

  useEffect(() => {
    // getMachineName();
    getSalespersons();
    getTerminals();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    generateSummeryReport();
    // eslint-disable-next-line
  }, []);

  useEffect(() => {
    if (isShowGenerateButton === null) return;
    setIsShowGenerateButton(isAnyChangeOnReportsFilters());
  }, [isAnyChangeOnReportsFilters, isShowGenerateButton]);

  const generateSummeryReport = () => {
    loadBillSummaryReports();
    setIsShowGenerateButton(false);
    setPrevFilterData({
      filterFromDate,
      filterToDate,
      filterSalesperson,
      filterTerminal,
    });
  };

  const getSalespersons = async () => {
    const res = await SalespersonsService.getSalesPersons();
    setSalesPersons(res);
  };
  const getTerminals = async () => {
    const res = await TerminalService.getTerminals();
    setTerminals(res);
  };

  const toCsvBtnPressed = async () => {
    const filteredCsvColumns = getFilteredCSVColumns(
      CSV_COLUMNS,
      optionalColumns,
      STORAGE_KEYS.SALES_REPORT_COLUMN_FILTER
    );
    const csv = CsvHelper.getString(items, filteredCsvColumns);
    const blob = new Blob([csv], {
      type: 'text/csv',
    });
    saveAs(blob, `sales-tax-summary-${new Date().toISOString()}.csv`);

    postAlertMessage({
      text: 'Exported to excel successfully',
      type: 'success',
    });
  };

  const loadBillSummaryReports = async ({
    startTime = filterFromDate,
    endTime = filterToDate,
    cashier_ids = filterSalesperson,
    terminal_ids = filterTerminal,
  } = {}) => {
    if (DateTimeHelpers.reportDateLimit(startTime, endTime)) {
      setDateLimitPopup(true);
      return;
    }
    setLoadingIndicator(true);
    try {
      const timezoneFreeStartTime = new Date(startTime.getTime() - startTime.getTimezoneOffset() * 60000);
      const timezoneFreeEndTime = new Date(endTime.getTime() - endTime.getTimezoneOffset() * 60000);
      const data = {
        filter_start_time: timezoneFreeStartTime,
        filter_end_time: timezoneFreeEndTime,
        terminal_ids: terminal_ids.length ? terminal_ids : null,
        cashier_ids: cashier_ids.length ? cashier_ids : null,
      };
      const resp = await BillSummaryReportService.getBillSummaryReport(data);
      setItems(resp);
      setFilteredItems(resp);
    } catch (error) {
      postAlertMessage({ text: error.message, type: 'failed' });
    }

    setLoadingIndicator(false);
  };

  const handleSearch = (value) => {
    setSearchText(value);
    setFilteredItems(
      items.filter(
        (item) => item.bill_no.toString().includes(value.toLowerCase()) || item.date.includes(value.toLowerCase())
      )
    );
  };

  const _getBillReportSummary = (items) => {
    let totalAmount = 0;
    let totalTax = 0;
    let totalAdditionalTax = 0;
    let totalTaxAmount = 0;
    let discount = 0;
    items.forEach((item) => {
      totalAmount += item.total;
      totalTax += item.tax_amount;
      totalAdditionalTax += item.cess_amount;
      discount += item.discount_applied_on_items + item.discount;
      totalTaxAmount += item.tax_amount + item.cess_amount;
    });

    return {
      totalAmount: ShopsHelper.getAmountFormatted(shop, totalAmount),
      totalTax: ShopsHelper.getAmountFormatted(shop, totalTax),
      totalAdditionalTax: ShopsHelper.getAmountFormatted(shop, totalAdditionalTax),
      discount: ShopsHelper.getAmountFormatted(shop, discount),
      totalTaxAmount: ShopsHelper.getAmountFormatted(shop, totalTaxAmount),
    };
  };

  const headerData = [
    {
      label: 'Bill No',
      id: 'bill_no',
      type: 'callback',
      viewRender: (obj) => (obj.bill_no ? obj.bill_no : obj.fid),
    },
    {
      label: 'Date',
      id: 'date',
      type: 'text',
    },
    {
      label: 'Time',
      id: 'time',
      type: 'callback',
      viewRender: (item) => DateTimeHelpers.formatServerTimeSegment(item.time),
    },
    {
      label: 'Customer',
      id: 'customer_name',
      type: 'text',
    },
    {
      label: 'Mobile',
      id: 'customer_mobile',
      type: 'text',
    },
    {
      label: 'Tax ID',
      id: 'customer_tax_id',
      type: 'text',
    },
    {
      label: 'Discount',
      id: 'discount_applied_on_items',
      type: 'callback',
      viewRender: (item) => {
        return `${shop?.currency || ''} ${ShopsHelper.getAmountFormatted(
          shop,
          item.discount + item.discount_applied_on_items,
          shop?.tax_region
        )}`;
      },
    },
    {
      label: 'Net Amount',
      id: 'net_amount',
      type: 'callback',
      viewRender: (item) => {
        return `${shop?.currency || ''} ${ShopsHelper.getAmountFormatted(
          shop,
          item.total - item.tax_amount + (item.discount + item.discount_applied_on_items),
          shop?.tax_region
        )}`;
      },
    },
    {
      label: 'Tax Amount',
      id: 'tax_amount',
      type: 'floatAmount',
    },
    {
      label: 'Additional Tax',
      id: 'cess_amount',
      type: 'floatAmount',
    },
    {
      label: 'Total Tax',
      id: 'total_tax',
      type: 'callback',
      viewRender: (item) => {
        return ShopsHelper.getAmountFormatted(shop, item.tax_amount + item.cess_amount);
      },
    },
    {
      label: 'Total Price',
      id: 'total',
      type: 'callback',
      viewRender: (item) => {
        return `${shop?.currency || ''} ${ShopsHelper.getAmountFormatted(shop, item.total)}`;
      },
    },
  ];

  const optionalColumns = {
    customer_name: false,
    customer_mobile: false,
    customer_tax_id: false,
    discount: true,
    net_amount: true,
  };

  const summary = _getBillReportSummary(filteredItems);

  return (
    <div className={styles.contentWrapper}>
      <Loader isOpen={loadingIndicator} />

      <div className={styles.titleSec}>
        <span className={styles.title}>
          Reports<span className={styles.menuTitle}>Generation</span>
        </span>
        <div style={{ justifyContent: 'flex-end', marginRight: '10px' }}>
          <div style={{ paddingBottom: '4px' }}>
            <label className={styles.label}>Print Report</label>
          </div>
          <Button
            variant="contained"
            color="primary"
            className={styles.actionBtn}
            style={{ backgroundColor: '#00a65a' }}
            onClick={window.print}
          >
            <PrintIcon className={styles.actionBtnIcon} />
            Print
          </Button>
        </div>
        <div style={{ justifyContent: 'flex-end' }}>
          <div style={{ paddingBottom: '4px' }}>
            <label className={styles.label}>Export As</label>
          </div>
          <Button
            variant="contained"
            color="primary"
            className={styles.actionBtn}
            style={{ backgroundColor: '#00a65a' }}
            onClick={toCsvBtnPressed}
          >
            <ImportExportIcon className={styles.actionBtnIcon} />
            CSV
          </Button>
        </div>
      </div>
      <div className={styles.changeable}>
        <div className={styles.filterSec}>
          <div className={styles.headTitle}>
            <h2 className={styles.subTitle}>
              Sales Tax Summary Report for {DateTimeHelpers.convertDateToDMY(filterFromDate)} to{' '}
              {DateTimeHelpers.convertDateToDMY(filterToDate)}
            </h2>
          </div>
          <div className={styles.filerInputSec}>
            <div className={styles.searchSec}>
              <input
                type="text"
                value={searchText}
                onChange={(e) => {
                  handleSearch(e.target.value);
                }}
                className={styles.searchInput}
                placeholder="Search by bill no, date"
              />
              <Tooltip title="Search" placement="bottom">
                <SearchIcon className={styles.searchIcon} />
              </Tooltip>
            </div>
          </div>
        </div>
        <div className={styles.actionButtons}>
          <div className={styles.dateDiv}>
            <div style={{ paddingBottom: '4px' }}>
              <label className={styles.label}>From</label>
            </div>
            <div>
              <MuiPickersUtilsProvider utils={DateFnsUtils}>
                <KeyboardDatePicker
                  disableFuture
                  format="dd/MM/yyyy"
                  id="date-picker-from"
                  className={styles.dateBox}
                  value={filterFromDate}
                  onChange={(date) => {
                    setFilterFromDate(date);
                    // loadBillSummaryReports({ startTime: date });
                  }}
                  KeyboardButtonProps={{
                    'aria-label': 'Change date',
                  }}
                />
                <KeyboardTimePicker
                  disableFuture
                  id="time-picker-from"
                  className={styles.dateBox}
                  value={filterFromDate}
                  onChange={(date) => {
                    setFilterFromDate(date);
                    // loadBillSummaryReports({ startTime: date });
                  }}
                  KeyboardButtonProps={{
                    'aria-label': 'Change Time',
                  }}
                />
              </MuiPickersUtilsProvider>
            </div>
          </div>
          <div className={styles.dateDiv}>
            <div style={{ paddingBottom: '4px' }}>
              <label className={styles.label}>To</label>
            </div>
            <div>
              <MuiPickersUtilsProvider utils={DateFnsUtils}>
                <KeyboardDatePicker
                  disableFuture
                  format="dd/MM/yyyy"
                  id="date-picker-to"
                  className={styles.dateBox}
                  value={filterToDate}
                  onChange={(date) => {
                    setFilterToDate(date);
                    // loadBillSummaryReports({ endTime: date });
                  }}
                  KeyboardButtonProps={{
                    'aria-label': 'Change date',
                  }}
                />
                <KeyboardTimePicker
                  disableFuture
                  id="time-picker-to"
                  className={styles.dateBox}
                  value={filterToDate}
                  onChange={(date) => {
                    setFilterToDate(date);
                    // loadBillSummaryReports({ endTime: date });
                  }}
                  KeyboardButtonProps={{
                    'aria-label': 'Change Time',
                  }}
                />
              </MuiPickersUtilsProvider>
            </div>
          </div>
          <div className={styles.filterDiv}>
            <div style={{ paddingBottom: '4px' }}>
              <label className={styles.label}>Terminal</label>
            </div>
            <div>
              <FormControl sx={{ m: 1, width: 300 }}>
                <Select
                  labelId="demo-multiple-checkbox-label"
                  id="demo-multiple-checkbox"
                  multiple
                  displayEmpty
                  value={filterTerminal}
                  onChange={(e) => setFilterTerminal(e.target.value)}
                  input={<OutlinedInput />}
                  style={{ width: 135, height: 40 }}
                  renderValue={(filterTerminal) => {
                    if (filterTerminal.length === 0) return <span>All</span>;
                    const selectedNames = filterTerminal.map((id) => terminals.find((tr) => tr.id === id).name);
                    return selectedNames.join(', ');
                  }}
                  MenuProps={SelectFilterStyle}
                >
                  {terminals.map((terminal) => (
                    <MenuItem key={terminal.id} value={terminal.id}>
                      <Checkbox checked={filterTerminal.includes(terminal.id)} color="primary" />
                      <ListItemText primary={terminal.name} />
                    </MenuItem>
                  ))}
                </Select>
              </FormControl>
            </div>
          </div>
          <div className={styles.filterDiv}>
            <div style={{ paddingBottom: '4px' }}>
              <label className={styles.label}>Salesperson</label>
            </div>
            <div>
              <FormControl sx={{ m: 1, width: 300 }}>
                <Select
                  labelId="demo-multiple-checkbox-label"
                  id="demo-multiple-checkbox"
                  multiple
                  displayEmpty
                  value={filterSalesperson}
                  onChange={(e) => setFilterSalesperson(e.target.value)}
                  input={<OutlinedInput />}
                  style={{ width: 135, height: 40 }}
                  renderValue={(filterSalesperson) => {
                    if (filterSalesperson.length === 0) return <span>All</span>;
                    const selectedNames = filterSalesperson.map(
                      (id) => salesPersons.find((person) => person.id === id).name
                    );
                    return selectedNames.join(', ');
                  }}
                  MenuProps={SelectFilterStyle}
                >
                  {salesPersons.map((salesperson) => (
                    <MenuItem key={salesperson.id} value={salesperson.id}>
                      <Checkbox checked={filterSalesperson.includes(salesperson.id)} color="primary" />
                      <ListItemText primary={salesperson?.name} />
                    </MenuItem>
                  ))}
                </Select>
              </FormControl>
            </div>
          </div>
          <div className={styles.filterDiv}>
            <div style={{ paddingBottom: '4px' }}>
              <label className={styles.label}>Generate Report</label>
            </div>
            <div>
              <Button
                variant="contained"
                color="primary"
                className={styles.actionBtn}
                style={{ backgroundColor: '#00a65a' }}
                onClick={generateSummeryReport}
                disabled={isShowGenerateButton === false}
              >
                <SyncIcon className={styles.actionBtnIcon} />
                Generate
              </Button>
            </div>
          </div>
        </div>
      </div>
      {isShowGenerateButton === null ? (
        <Info
          title={'Set filters and click generate button'}
          content={
            'Reports are generated based on the filters. Please set filters and click generate button to the generate report.'
          }
        />
      ) : (
        items &&
        (filteredItems && filteredItems.length ? (
          <div className={styles.tableContainer}>
            <Grayout open={isShowGenerateButton} />
            <DataTable
              columns={headerData}
              rows={filteredItems ? filteredItems : items}
              rowKey="id"
              summary={{
                date: 'Total',
                total: `${shop?.currency || ''} ${summary.totalAmount}`,
                tax_amount: `${shop?.currency || ''} ${summary.totalTax}`,
                cess_amount: `${shop?.currency || ''} ${summary.totalAdditionalTax}`,
                discount_applied_on_items: `${shop?.currency || ''}  ${summary.discount}`,
                total_tax: `${shop?.currency || ''}  ${summary.totalTaxAmount}`,
              }}
              columnFilterId={STORAGE_KEYS.SALES_REPORT_COLUMN_FILTER}
              optionalColumns={optionalColumns}
            />
          </div>
        ) : (
          !loadingIndicator && (
            <Info
              severity="warning"
              title={'Oh no, there are no sales matching to your filter'}
              content={'There are no sales matching to your filter. Please verify your inputs to generate report.'}
            />
          )
        ))
      )}

      {dateLimitPopup && (
        <DateLimitDialog
          handleClose={() => {
            setDateLimitPopup(false);
          }}
        />
      )}
    </div>
  );
}

export default withConsoleBase(SalesTaxSummaryReport);
