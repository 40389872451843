import { Button, Grid, TextField } from '@material-ui/core';
import React, { useState, useContext } from 'react';
import styles from './styles.module.css';
import CloseIcon from '@material-ui/icons/Close';
import CustomerService from '../../services/CustomerService';
import { DialogActions } from '@mui/material';
import { AlertMessagesContext } from 'react-alert-messages';

export default function NewCustomerGroupDialog(props) {
  const { toggleAddTab } = props;
  const { postAlertMessage } = useContext(AlertMessagesContext);

  const [name, setName] = useState('');
  const [description, setDescription] = useState('');
  const [loadingIndicator, setLoadingIndicator] = useState(false);

  const createNewCustomer = async () => {
    const data = {
      name,
      description,
    };
    setLoadingIndicator(true);
    try {
      await CustomerService.createCustomerGroup(data);
      postAlertMessage({
        text: 'Customer group created successfully',
        type: 'success',
      });
      toggleAddTab('save');
    } catch (error) {
      postAlertMessage({ text: error.message, type: 'failed' });
      toggleAddTab();
    }
    setLoadingIndicator(false);
  };

  return (
    <div className={styles.popUp}>
      <div className={styles.contentWrapper}>
        <div className={styles.headSec}>
          <h2 className={styles.editTitle}>Create New Customer Group</h2>
          <CloseIcon onClick={toggleAddTab} />
        </div>
        <div className={styles.inputSec}>
          <Grid container className={styles.inputRow} alignItems="center">
            <Grid item xs={4} sm={4} spacing={2} justify="flex-end" className={styles.inputLabelContainer}>
              <h3 className={styles.inputLabel}>Name</h3>
            </Grid>
            <Grid item xs={8} sm={8}>
              <TextField
                label="Name"
                variant="outlined"
                size="small"
                name="name"
                value={name}
                onChange={(e) => setName(e.target.value)}
                fullWidth
              />
            </Grid>
          </Grid>
          <Grid container className={styles.inputRow} alignItems="center">
            <Grid item xs={4} sm={4} spacing={2} justify="flex-end" className={styles.inputLabelContainer}>
              <h3 className={styles.inputLabel}>Description</h3>
            </Grid>
            <Grid item xs={8} sm={8}>
              <TextField
                label="Description"
                variant="outlined"
                size="small"
                name="location"
                value={description}
                onChange={(e) => setDescription(e.target.value)}
                fullWidth
              />
            </Grid>
          </Grid>
          <DialogActions>
            <Button variant="contained" color="secondary" size="small" onClick={toggleAddTab}>
              close
            </Button>
            <Button
              variant="contained"
              size="small"
              color="primary"
              style={{ backgroundColor: '#00a65a' }}
              disabled={name === '' || loadingIndicator}
              onClick={createNewCustomer}
            >
              Create Group
            </Button>
          </DialogActions>
        </div>
      </div>
    </div>
  );
}
