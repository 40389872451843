import React, { useCallback, useContext, useEffect, useState } from 'react';
import { Button, Tooltip } from '@material-ui/core';
import styles from './styles.module.css';
import DataTable from '../../utils/DataTable';
import SalesPersonFormDialog from '../../popups/SalesPersonFormDialog';
import SalesPersonService from '../../../services/SalesPersonService';
import NoteAddIcon from '@material-ui/icons/NoteAdd';
import SearchIcon from '@material-ui/icons/Search';
import Loader from '../../utils/Loading';
import Info from '../../utils/Alert/Info';
import Error from '../../utils/Alert/Error';
import Success from '../../utils/Alert/Success';
import withConsoleBase from '../../utils/ConsoleBase/withConsoleBase';
import AlertHelper from '../../../helpers/AlertHelper';
import { ShopContext } from '../../../Context/ShopContext';
import ConfirmPopup from '../../utils/Alert/ConfirmPopup';

function SalesPersons() {
  const { shop } = useContext(ShopContext);

  const [salesPersons, setSalesPersons] = useState(null);
  const [salesPersonsFiltered, setSalesPersonsFiltered] = useState(null);
  const [searchText, setSearchText] = useState('');
  const [isLoading, setLoading] = useState(false);
  const [selectedForDelete, setSelectedForDelete] = useState(null);
  const [selectedForEdit, setSelectedForEdit] = useState(null);
  const [showAddForm, setShowAddForm] = useState(false);
  const [errorMsg, setErrorMsg] = useState();
  const [successMsg, setSuccessMsg] = useState();

  const handleSalesPersonActionClick = (salesPerson) => setSelectedForDelete(salesPerson);
  const handleEditClick = (salesPerson) => setSelectedForEdit(salesPerson);
  const handleAddClick = () => setShowAddForm(true);

  const handleDeleteSalesPerson = async () => {
    setLoading(true);
    try {
      const data = { ...selectedForDelete, is_active: !selectedForDelete.is_active };
      const resp = await SalesPersonService.updateSalesPersons(selectedForDelete.id, data);
      getSalesPersons();
      AlertHelper.setShortMessage(
        setSuccessMsg,
        `Salesperson ${resp?.is_active === true ? 'activated' : 'deactivated'} successfully`
      );
    } catch (err) {
      AlertHelper.setShortMessage(setErrorMsg, err.message);
    }
    setLoading(false);
  };

  const handleSearch = (value) => {
    setSearchText(value);
    const filterResponse = salesPersons
      ? salesPersons.filter(
          (salesPerson) =>
            salesPerson.name.toLowerCase().includes(value.toLowerCase()) ||
            salesPerson.username.toLowerCase().includes(value.toLowerCase()) ||
            salesPerson.id.toString().includes(value)
        )
      : [];
    setSalesPersonsFiltered(filterResponse);
  };
  const getSalesPersons = useCallback(async () => {
    setLoading(true);
    const salesPersons = await SalesPersonService.getSalesPersons();
    setSalesPersons(salesPersons);
    setLoading(false);
  }, []);

  const headerData = [
    {
      label: 'ID',
      id: 'id',
      type: 'text',
    },
    {
      label: 'Name',
      id: 'name',
      type: 'text',
    },
    {
      label: 'Username',
      id: 'username',
      type: 'text',
    },
    {
      label: 'Password',
      id: 'password',
      type: 'text',
    },
    {
      label: 'Active',
      id: 'is_active',
      type: 'callback',
      viewRender: (obj) => {
        return obj.is_active === true ? 'Yes' : 'No';
      },
    },
    {
      label: 'Edit',
      id: 'updateSalesPerson',
      type: 'button',
      title: 'Edit',
      clickHandler: handleEditClick,
    },
    {
      id: 'deleteBtn',
      type: 'callback',
      align: 'right',
      viewRender: (obj) => (
        <Button
          onClick={(e) => {
            handleSalesPersonActionClick(obj);
            e.stopPropagation();
          }}
          variant="contained"
          size="small"
          color="primary"
        >
          {obj.is_active ? 'Deactivate' : 'Activate'}
        </Button>
      ),
    },
  ];

  useEffect(() => {
    if (!shop?.id) return;
    getSalesPersons();
  }, [getSalesPersons, shop?.id]);

  return (
    <div className={styles.contentWrapper}>
      <Loader isOpen={isLoading} />
      <div className={styles.titleSec}>
        <h2 className={styles.title}>
          Salespersons <span className={styles.menuTitle}>Management</span>
        </h2>
      </div>
      <div className={styles.changeable}>
        <div className={styles.filterSec}>
          <h2 className={styles.subTitle}>Salespersons</h2>

          <div className={styles.filerInputSec}>
            <Button
              variant="contained"
              color="primary"
              size="small"
              style={{ backgroundColor: '#ff851b' }}
              className={styles.actionBtn}
              onClick={handleAddClick}
            >
              <NoteAddIcon className={styles.actionBtnIcon} />
              New Salesperson
            </Button>
            <div className={styles.searchSec}>
              <input
                type="text"
                value={searchText}
                onChange={(e) => {
                  handleSearch(e.target.value);
                }}
                className={styles.searchInput}
                placeholder="search items"
              />
              <Tooltip title="Search" placement="bottom">
                <SearchIcon className={styles.searchIcon} />
              </Tooltip>
            </div>
          </div>
        </div>
      </div>
      {errorMsg && (
        <div className={styles.marginTop}>
          <Error title={errorMsg} />
        </div>
      )}
      {successMsg && (
        <div className={styles.marginTop}>
          <Success title={successMsg} />
        </div>
      )}

      {salesPersons &&
        (salesPersons.length ? (
          <DataTable columns={headerData} rows={salesPersonsFiltered ? salesPersonsFiltered : salesPersons} />
        ) : (
          <Info
            title={'No salespersons to list'}
            content={
              'You have no salespersons to list with current filter configuration. Please clear the filters or create a new salesperson'
            }
          />
        ))}
      {selectedForEdit && (
        <SalesPersonFormDialog
          handleClose={() => setSelectedForEdit(null)}
          handleSuccess={() => {
            setSelectedForEdit(null);
            getSalesPersons();
          }}
          salesPerson={selectedForEdit}
          setSuccessMsg={setSuccessMsg}
        />
      )}
      {showAddForm && (
        <SalesPersonFormDialog
          handleClose={() => setShowAddForm(false)}
          handleSuccess={() => {
            setShowAddForm(false);
            getSalesPersons();
          }}
          setErrorMsg={setErrorMsg}
          setSuccessMsg={setSuccessMsg}
        />
      )}
      {selectedForDelete && (
        <ConfirmPopup
          data={selectedForDelete}
          handleClose={() => setSelectedForDelete(null)}
          handleOkay={handleDeleteSalesPerson}
          alertTitle={`Confirm ${selectedForDelete.is_active ? 'De-activate' : 'Activate'}`}
          successBtnName={`${selectedForDelete.is_active ? 'Deactivate' : 'Activate'}`}
          alertContent={`${
            selectedForDelete.is_active
              ? 'Are you sure you want to deactivate this salesperson?'
              : 'Are you sure you want to activate this salesperson?'
          }`}
        />
      )}
    </div>
  );
}

export default withConsoleBase(SalesPersons);
