import React, { useEffect, useState, useContext } from 'react';
import styles from './styles.module.css';
import { TextField, Grid, Box, Button } from '@material-ui/core';
import { ROUTES, STORAGE_KEYS, REGISTRATION_TYPE, REGISTRATION_CHANNEL, BLOCKED_PASSWORDS } from '../../../const';
import AuthService from '../../../services/AuthService';
import { useHistory, Link, useLocation } from 'react-router-dom';
import Loader from '../../utils/Loading';
import { OnlinePurchaseContext } from '../../../Context/OnlinePurchaseContext';
import { isValidEmail } from '../../../helpers/StringHelper';

export default function NewAccount(props) {
  const { loadCountryCode, isLoading: isLoadingInPurchaseContext } = useContext(OnlinePurchaseContext);
  const [data, setData] = useState({});
  const [isLoading, setIsLoding] = useState(false);
  const [errorMessage, setErrorMessage] = useState();
  const history = useHistory();
  const location = useLocation();
  const queryParams = new URLSearchParams(location.search);
  const registration_type = queryParams.get('registration_type');
  const registration_channel = queryParams.get('registration_channel');

  const signupBtnPressed = async (e) => {
    setErrorMessage(null);

    if (
      !data.shop_name ||
      !data.username ||
      !data.email ||
      !data.first_name ||
      !data.last_name ||
      !data.mobile ||
      !data.password ||
      !data.country
    ) {
      setErrorMessage('Required field should not be empty.\n Fill the form correctly.');
      return;
    }

    if (!isValidEmail(data.email)) {
      setErrorMessage('Invalid email provided');
      return;
    }

    if (BLOCKED_PASSWORDS.includes(data.password)) {
      setErrorMessage('Your password is too weak. Please choose a stronger one.');
      return;
    }

    setIsLoding(true);
    try {
      const signupData = {
        ...data,
        mac_id: localStorage.getItem(STORAGE_KEYS.MAC_ID) || null,
        registration_key: localStorage.getItem(STORAGE_KEYS.REGISTRSATION_KEY) || null,
      };
      const params = { registration_channel, registration_type };
      const error = await AuthService.createAccount(signupData, params);
      if (error) {
        setErrorMessage('Failed to initiate payment! Please contact our support team.');
        return;
      }

      if (registration_type === REGISTRATION_TYPE.TRIAL || registration_channel === REGISTRATION_CHANNEL.KEY) {
        localStorage.removeItem(STORAGE_KEYS.REGISTRSATION_KEY);
        localStorage.removeItem(STORAGE_KEYS.MAC_ID);
        history.push(ROUTES.ONBOARDING);
        return;
      }
      history.push(ROUTES.PROCESS_PAYMENT);
    } catch (err) {
      setErrorMessage(err.message);
    }
    setIsLoding(false);
  };

  useEffect(() => {
    const getCountryConfig = async () => {
      const countryData = await loadCountryCode();
      setData((prev) => ({ ...prev, country: countryData?.code ?? null, currency: countryData?.currency ?? '$' }));
    };

    getCountryConfig();
    //eslint-disable-next-line
  }, []);

  return (
    <Grid className={styles.mainDiv}>
      <Loader isOpen={isLoading || isLoadingInPurchaseContext} backdropColor={'#ffffff'} />
      <Box container className={styles.outerBoxFrameWithoutAd}>
        <Grid style={{ boxShadow: 3 }} item xs={12} sm={12}>
          <Grid className={styles.formBoxFrame}>
            <h3 className={styles.titleText}>Create a new account</h3>
            <div className={styles.textMarg}>
              <TextField
                name="shopname"
                size="small"
                label="Shop Name"
                type="text"
                required
                color="secondary"
                fullWidth
                onChange={(event) => {
                  setData({ ...data, shop_name: event.target.value });
                }}
              />
            </div>
            <div className={styles.textMarg}>
              <TextField
                name="email"
                size="small"
                label="Email"
                type="email"
                required
                color="secondary"
                fullWidth
                onChange={(event) => {
                  setData({ ...data, email: event.target.value });
                }}
              />
            </div>
            <div className={styles.textMarg}>
              <TextField
                name="firstname"
                size="small"
                id="outlined-password-input"
                label="Name"
                type="text"
                required
                color="secondary"
                fullWidth
                onChange={(event) => {
                  setData({
                    ...data,
                    first_name: event.target.value,
                    last_name: '-',
                  });
                }}
              />
            </div>
            <div className={styles.textMarg}>
              <TextField
                name="username"
                size="small"
                id="outlined-password-input"
                label="Username"
                type="text"
                required
                color="secondary"
                fullWidth
                onChange={(event) => {
                  setData({ ...data, username: event.target.value });
                }}
              />
            </div>
            <div className={styles.textMarg}>
              <TextField
                name="password"
                size="small"
                id="outlined-password-input"
                label="Password"
                type="password"
                required
                color="secondary"
                fullWidth
                onChange={(event) => {
                  setData({ ...data, password: event.target.value });
                }}
              />
            </div>
            <div className={styles.textMarg}>
              <TextField
                name="mobilenumber"
                size="small"
                id="outlined-password-input"
                label="Mobile Number"
                type="number"
                required
                color="secondary"
                fullWidth
                onChange={(event) => {
                  setData({ ...data, mobile: event.target.value });
                }}
              />
            </div>
            <div className={styles.errorMsg} style={{ marginTop: '8px' }}>
              {errorMessage && <span>{errorMessage}</span>}
            </div>
            <div className={styles.loginLoader}>{isLoading && <Loader />}</div>
            <Grid className={styles.textCenter}>
              <Button
                style={{ backgroundColor: '#272C86' }}
                variant="contained"
                color="secondary"
                fullWidth
                onClick={signupBtnPressed}
                disabled={isLoading}
              >
                Signup
              </Button>
            </Grid>
            <Grid className={styles.forgotPasswordWrapper}>
              <Link to="/login" className={styles.forgotPassword}>
                Goto login?
              </Link>
            </Grid>
          </Grid>
        </Grid>
      </Box>
    </Grid>
  );
}
