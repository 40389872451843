import React, { useCallback, useContext, useEffect, useState } from 'react';
import SearchIcon from '@material-ui/icons/Search';
import {
  Button,
  MenuItem,
  ListItemText,
  OutlinedInput,
  Checkbox,
  Select,
  FormControl,
  Tooltip,
} from '@material-ui/core';
import styles from './styles.module.css';
import DataTable from '../../utils/DataTable';
import BillSummaryReportService from '../../../services/BillSummaryReportService';
import SalespersonsService from '../../../services/SalesPersonService';
import DateTimeHelpers from '../../../helpers/DateTimeHelpers';
import ImportExportIcon from '@material-ui/icons/ImportExport';
import SyncIcon from '@mui/icons-material/Sync';
import { saveAs } from 'file-saver';
import { KeyboardDatePicker, MuiPickersUtilsProvider } from '@material-ui/pickers';
import 'date-fns';
import DateFnsUtils from '@date-io/date-fns';
import Info from '../../utils/Alert/Info';
import Loader from '../../utils/Loading';
import withConsoleBase from '../../utils/ConsoleBase/withConsoleBase';
import { SUBSCRIPTION_TYPE, SelectFilterStyle } from '../../../const';
import { ShopContext } from '../../../Context/ShopContext';
import CsvHelper from '../../../helpers/CsvHelper';
import { AlertMessagesContext } from 'react-alert-messages';
import PrintIcon from '@mui/icons-material/Print';
import ShopsHelper from '../../../helpers/ShopsHelper';
import Grayout from '../../utils/GrayOut/Grayout';
import TerminalService from '../../../services/TerminalServices';
import DateLimitDialog from '../../popups/DateLimitDialog';
import RestrictionInfo from '../../utils/Alert/RestrictionInfo';

function ProfitDayWiseReport() {
  const { shop } = useContext(ShopContext);
  const { postAlertMessage } = useContext(AlertMessagesContext);

  const [loadingIndicator, setLoadingIndicator] = useState(false);
  const [filterFromDate, setFilterFromDate] = useState(DateTimeHelpers.getFiveDateEarlier());
  const [filterToDate, setFilterToDate] = useState(new Date());
  const [prevFilterData, setPrevFilterData] = useState({
    filterFromDate,
    filterToDate,
  });
  const [terminals, setTerminals] = useState([]);
  const [filterTerminal, setFilterTerminal] = useState([]);
  const [salesPersons, setSalesPersons] = useState([]);
  const [filterSalesperson, setFilterSalesperson] = useState([]);
  const [items, setItems] = useState([]);
  const [searchText, setSearchText] = useState('');
  const [filteredItems, setFilteredItems] = useState([]);
  const [isShowGenerateButton, setIsShowGenerateButton] = useState(null);
  const [dateLimitPopup, setDateLimitPopup] = useState(false);

  const CSV_COLUMNS = {
    date: 'Date',
    sales: 'Sales',
    purchase_cost: 'Purchase Cost',
    purchase_tax: 'Purchase Tax',
    purchase_expense: 'Expense',
    tax: 'Tax Amount',
    cess: 'Additional tax',
    discount: 'Discount',
    profit: 'Profit',
    total: 'Total Price',
  };

  const isAnyChangeOnReportsFilters = useCallback(() => {
    return (
      filterFromDate !== prevFilterData.filterFromDate ||
      filterToDate !== prevFilterData.filterToDate ||
      filterTerminal !== prevFilterData.filterTerminal ||
      filterSalesperson !== prevFilterData.filterSalesperson
    );
  }, [
    filterFromDate,
    filterToDate,
    filterTerminal,
    filterSalesperson,
    prevFilterData.filterFromDate,
    prevFilterData.filterToDate,
    prevFilterData.filterTerminal,
    prevFilterData.filterSalesperson,
  ]);

  useEffect(() => {
    // getMachineName();
    if (subscriptionType && subscriptionType !== SUBSCRIPTION_TYPE.PREMIUM) return;
    generateSummeryReport();
    getSalespersons();
    getTerminals();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    if (isShowGenerateButton === null) return;
    setIsShowGenerateButton(isAnyChangeOnReportsFilters());
  }, [isAnyChangeOnReportsFilters, isShowGenerateButton]);

  const generateSummeryReport = () => {
    loadBillSummaryReports();
    setIsShowGenerateButton(false);
    setPrevFilterData({
      filterFromDate,
      filterToDate,
      filterSalesperson,
      filterTerminal,
    });
  };

  const getTerminals = async () => {
    const res = await TerminalService.getTerminals();
    setTerminals(res);
  };

  const getSalespersons = async () => {
    const res = await SalespersonsService.getSalesPersons();
    setSalesPersons(res);
  };

  const toCsvBtnPressed = async () => {
    const csv = CsvHelper.getString(items, CSV_COLUMNS);
    const blob = new Blob([csv], {
      type: 'text/csv',
    });
    saveAs(blob, `profit-day-wise-${new Date().toISOString()}.csv`);

    postAlertMessage({
      text: 'Exported to excel successfully',
      type: 'success',
    });
  };

  const _getBillReportSummary = (items) => {
    let totalExpence = 0;
    let totalAmount = 0;
    let totalProfit = 0;
    let totalTax = 0;
    let totalAdditionalTax = 0;
    let totalDiscount = 0;
    items.forEach((item) => {
      totalExpence += item.purchase_expense;
      totalAmount += item.total;
      totalProfit += item.profit;
      totalTax += item.tax;
      totalAdditionalTax += item.cess;
      totalDiscount += item.discount;
    });

    return {
      totalExpence: ShopsHelper.getAmountFormatted(shop, totalExpence),
      totalProfit: ShopsHelper.getAmountFormatted(shop, totalProfit),
      totalAmount: ShopsHelper.getAmountFormatted(shop, totalAmount),
      totalTax: ShopsHelper.getAmountFormatted(shop, totalTax),
      totalAdditionalTax: ShopsHelper.getAmountFormatted(shop, totalAdditionalTax),
      totalDiscount: ShopsHelper.getAmountFormatted(shop, totalDiscount),
    };
  };

  const loadBillSummaryReports = async ({
    startTime = filterFromDate,
    endTime = filterToDate,
    cashier_ids = filterSalesperson,
    terminal_ids = filterTerminal,
  } = {}) => {
    if (DateTimeHelpers.reportDateLimit(startTime, endTime)) {
      setDateLimitPopup(true);
      return;
    }
    setLoadingIndicator(true);
    try {
      const data = {
        filter_start_time: DateTimeHelpers.startOfDay(filterFromDate),
        filter_end_time: DateTimeHelpers.endOfDay(filterToDate),
        terminal_ids: terminal_ids.length ? terminal_ids : null,
        cashier_ids: cashier_ids.length ? cashier_ids : null,
      };
      const resp = await BillSummaryReportService.getDayWiseReportWithProfit(data);
      setItems(resp);
      setFilteredItems(resp);
    } catch (error) {
      postAlertMessage({ text: error.message, type: 'failed' });
    }
    setLoadingIndicator(false);
  };

  const handleSearch = (value) => {
    setSearchText(value);
    setFilteredItems(items.filter((item) => item.date.includes(value.toLowerCase())));
  };

  const subscriptionType = shop?.subscription?.type || '';
  const headerData = [
    {
      label: 'Date',
      id: 'date',
      type: 'text',
    },
    {
      label: 'Sales',
      id: 'num_bills',
      type: 'text',
    },
    {
      label: 'Tax Amount',
      id: 'tax',
      type: 'floatAmount',
    },
    {
      label: 'Additional Tax',
      id: 'cess',
      type: 'floatAmount',
    },
    {
      label: 'Purchase Cost',
      id: 'purchase_cost',
      type: 'callback',
      viewRender: (item) => {
        if (item.purchase_cost === '' || isNaN(item.purchase_cost)) {
          return item.purchase_cost;
        }
        return `${shop?.currency || ''} ${ShopsHelper.getAmountFormatted(shop, item.purchase_cost)}`;
      },
    },
    {
      label: 'Purchase Tax',
      id: 'purchase_tax',
      type: 'callback',
      viewRender: (item) => {
        if (item.purchase_tax === '' || isNaN(item.purchase_tax)) {
          return item.purchase_tax;
        }
        return `${shop?.currency || ''} ${ShopsHelper.getAmountFormatted(shop, item.purchase_tax)}`;
      },
    },
    {
      label: 'Expense',
      id: 'purchase_expense',
      type: 'callback',
      viewRender: (item) => {
        if (item.purchase_expense === '' || isNaN(item.purchase_expense)) {
          return item.purchase_expense;
        }
        return `${shop?.currency || ''} ${ShopsHelper.getAmountFormatted(shop, item.purchase_expense)}`;
      },
    },
    {
      label: 'Discount',
      id: 'discount',
      type: 'floatAmount',
    },
    {
      label: 'Profit*',
      id: 'profit',
      type: 'callback',
      viewRender: (item) => {
        if (item.profit === '' || isNaN(item.profit)) {
          return item.profit;
        }
        return `${shop?.currency || ''} ${ShopsHelper.getAmountFormatted(shop, item.profit)}`;
      },
    },
    {
      label: 'Total Price',
      id: 'total',
      type: 'callback',
      viewRender: (item) => {
        return `${shop?.currency || ''} ${ShopsHelper.getAmountFormatted(shop, item.total)}`;
      },
    },
  ];

  const summary = _getBillReportSummary(filteredItems);

  return (
    <div className={styles.contentWrapper}>
      <Loader isOpen={loadingIndicator} />
      <div className={styles.titleSec}>
        <span className={styles.title}>
          Reports<span className={styles.menuTitle}>Generation</span>
        </span>

        {subscriptionType && subscriptionType === SUBSCRIPTION_TYPE.PREMIUM && (
          <>
            <div style={{ justifyContent: 'flex-end', marginRight: '10px' }}>
              <div style={{ paddingBottom: '4px' }}>
                <label className={styles.label}>Print Report</label>
              </div>
              <Button
                variant="contained"
                color="primary"
                className={styles.actionBtn}
                style={{ backgroundColor: '#00a65a' }}
                onClick={window.print}
              >
                <PrintIcon className={styles.actionBtnIcon} />
                Print
              </Button>
            </div>
            <div style={{ justifyContent: 'flex-end' }}>
              <div style={{ paddingBottom: '4px' }}>
                <label className={styles.label}>Export As</label>
              </div>
              <Button
                variant="contained"
                color="primary"
                className={styles.actionBtn}
                style={{ backgroundColor: '#00a65a' }}
                onClick={toCsvBtnPressed}
              >
                <ImportExportIcon className={styles.actionBtnIcon} />
                CSV
              </Button>
            </div>
          </>
        )}
      </div>

      {subscriptionType && subscriptionType !== SUBSCRIPTION_TYPE.PREMIUM && (
        <RestrictionInfo
          title={'Feature not available '}
          content={'To get day wise report with profit option upgrade subscription to premium'}
        />
      )}

      {subscriptionType && subscriptionType === SUBSCRIPTION_TYPE.PREMIUM && (
        <>
          <div className={styles.changeable}>
            <div className={styles.filterSec}>
              <div className={styles.headTitle}>
                <h2 className={styles.subTitle}>
                  Day Wise Report With Profit for {DateTimeHelpers.convertDateToDMY(filterFromDate)} to{' '}
                  {DateTimeHelpers.convertDateToDMY(filterToDate)}
                </h2>
              </div>
              <div className={styles.filerInputSec}>
                <div className={styles.searchSec}>
                  <input
                    type="text"
                    value={searchText}
                    onChange={(e) => {
                      handleSearch(e.target.value);
                    }}
                    className={styles.searchInput}
                    placeholder="Search by bill no, date"
                  />
                  <Tooltip title="Search" placement="bottom">
                    <SearchIcon className={styles.searchIcon} />
                  </Tooltip>
                </div>
              </div>
            </div>
            <div className={styles.actionButtons}>
              <div className={styles.filterDiv}>
                <div style={{ paddingBottom: '4px' }}>
                  <label className={styles.label}>From</label>
                </div>
                <div>
                  <MuiPickersUtilsProvider utils={DateFnsUtils}>
                    <KeyboardDatePicker
                      disableFuture
                      format="dd/MM/yyyy"
                      id="date-picker-from"
                      className={styles.dateBox}
                      value={filterFromDate}
                      onChange={(date) => {
                        setFilterFromDate(date);
                      }}
                      KeyboardButtonProps={{
                        'aria-label': 'Change date',
                      }}
                    />
                  </MuiPickersUtilsProvider>
                </div>
              </div>
              <div className={styles.filterDiv}>
                <div style={{ paddingBottom: '4px' }}>
                  <label className={styles.label}>To</label>
                </div>
                <div>
                  <MuiPickersUtilsProvider utils={DateFnsUtils}>
                    <KeyboardDatePicker
                      disableFuture
                      format="dd/MM/yyyy"
                      id="date-picker-to"
                      className={styles.dateBox}
                      value={filterToDate}
                      onChange={(date) => {
                        setFilterToDate(date);
                      }}
                      KeyboardButtonProps={{
                        'aria-label': 'Change date',
                      }}
                    />
                  </MuiPickersUtilsProvider>
                </div>
              </div>
              <div className={styles.filterDiv}>
                <div style={{ paddingBottom: '4px' }}>
                  <label className={styles.label}>Terminal</label>
                </div>
                <div>
                  <FormControl sx={{ m: 1, width: 300 }}>
                    <Select
                      labelId="demo-multiple-checkbox-label"
                      id="demo-multiple-checkbox"
                      multiple
                      displayEmpty
                      value={filterTerminal}
                      onChange={(e) => setFilterTerminal(e.target.value)}
                      input={<OutlinedInput />}
                      style={{ width: 135, height: 40 }}
                      renderValue={(filterTerminal) => {
                        if (filterTerminal.length === 0) return <span>All</span>;
                        const selectedNames = filterTerminal.map((id) => terminals.find((tr) => tr.id === id).name);
                        return selectedNames.join(', ');
                      }}
                      MenuProps={SelectFilterStyle}
                    >
                      {terminals.map((terminal) => (
                        <MenuItem key={terminal.id} value={terminal.id}>
                          <Checkbox checked={filterTerminal.includes(terminal.id)} color="primary" />
                          <ListItemText primary={terminal.name} />
                        </MenuItem>
                      ))}
                    </Select>
                  </FormControl>
                </div>
              </div>
              <div className={styles.filterDiv}>
                <div style={{ paddingBottom: '4px' }}>
                  <label className={styles.label}>Salesperson</label>
                </div>
                <div>
                  <FormControl sx={{ m: 1, width: 300 }}>
                    <Select
                      labelId="demo-multiple-checkbox-label"
                      id="demo-multiple-checkbox"
                      multiple
                      displayEmpty
                      value={filterSalesperson}
                      onChange={(e) => setFilterSalesperson(e.target.value)}
                      input={<OutlinedInput />}
                      style={{ width: 135, height: 40 }}
                      renderValue={(filterSalesperson) => {
                        if (filterSalesperson.length === 0) return <span>All</span>;
                        const selectedNames = filterSalesperson.map(
                          (id) => salesPersons.find((person) => person.id === id).name
                        );
                        return selectedNames.join(', ');
                      }}
                      MenuProps={SelectFilterStyle}
                    >
                      {salesPersons.map((salesperson) => (
                        <MenuItem key={salesperson.id} value={salesperson.id}>
                          <Checkbox checked={filterSalesperson.includes(salesperson.id)} color="primary" />
                          <ListItemText primary={salesperson?.name} />
                        </MenuItem>
                      ))}
                    </Select>
                  </FormControl>
                </div>
              </div>
              <div className={styles.filterDiv}>
                <div style={{ paddingBottom: '4px' }}>
                  <label className={styles.label}>Generate Report</label>
                </div>
                <div>
                  <Button
                    variant="contained"
                    color="primary"
                    className={styles.actionBtn}
                    style={{ backgroundColor: '#00a65a' }}
                    onClick={generateSummeryReport}
                    disabled={isShowGenerateButton === false}
                  >
                    <SyncIcon className={styles.actionBtnIcon} />
                    Generate
                  </Button>
                </div>
              </div>
            </div>
          </div>
          {isShowGenerateButton === null ? (
            <Info
              title={'Set filters and click generate button'}
              content={
                'Reports are generated based on the filters. Please set filters and click generate button to the generate report.'
              }
            />
          ) : (
            items &&
            (filteredItems && filteredItems.length ? (
              <div className={styles.tableContainer}>
                <Grayout open={isShowGenerateButton} />
                <DataTable
                  columns={headerData}
                  rows={filteredItems ? filteredItems : items}
                  rowKey="id"
                  summary={{
                    date: 'Total',
                    purchase_expense: `${shop?.currency} ${summary.totalExpence}`,
                    tax: `${shop?.currency || ''} ${summary.totalTax}`,
                    cess: `${shop?.currency || ''} ${summary.totalAdditionalTax}`,
                    discount: `${shop?.currency || ''}  ${summary.totalDiscount}`,
                    profit: `${shop?.currency} ${summary.totalProfit}`,
                    total: `${shop?.currency} ${summary.totalAmount}`,
                  }}
                />
              </div>
            ) : (
              !loadingIndicator && (
                <Info
                  severity="warning"
                  title={'Oh no, there are no sales matching to your filter'}
                  content={'There are no sales matching to your filter. Please verify your inputs to generate report.'}
                />
              )
            ))
          )}
        </>
      )}

      {dateLimitPopup && (
        <DateLimitDialog
          handleClose={() => {
            setDateLimitPopup(false);
          }}
        />
      )}
    </div>
  );
}

export default withConsoleBase(ProfitDayWiseReport);
