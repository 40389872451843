import { STORAGE_KEYS } from '../const';
import { roundOff } from '../helpers/NumberFormatHelper';

function saveSelectedShopDetails(shop) {
  localStorage.setItem(STORAGE_KEYS.SHOP_ID, shop.id);
  localStorage.setItem(STORAGE_KEYS.SHOP_NAME, shop.shop_name);
  localStorage.setItem(STORAGE_KEYS.USER_TYPE, shop.subscription.type);
}

function getSubscriptionRemainingDays(shop) {
  if (!shop) return;
  const subscriptionEndDate = new Date(shop.subscription_end_date);
  const milliSecondsPerDay = 1000 * 60 * 60 * 24;
  const subscriptionRemainingDays = (subscriptionEndDate.getTime() - new Date().getTime()) / milliSecondsPerDay;
  return Math.round(subscriptionRemainingDays).toFixed(0);
}

function getAmountDecimalCount(shop) {
  return shop?.tax_region?.toLowerCase() === 'oman' ? 3 : 2;
}

function getAmountFormatted(shop, amount) {  
  return roundOff(amount, getAmountDecimalCount(shop));
}

const ShopsHelper = {
  saveSelectedShopDetails,
  getSubscriptionRemainingDays,
  getAmountDecimalCount,
  getAmountFormatted,
};

export default ShopsHelper;
