import BackendService from './BackendService';

async function getOrderActions() {
  return BackendService.getData('/order-actions/');
}
async function createOrderAction(data = {}) {
  return BackendService.postData('/order-actions/', data);
}
async function updateOrderAction(id, data = {}) {
  return BackendService.patch(`/order-actions/${id}/`, data);
}
async function getOrderActionTasks(params = {}) {
  return BackendService.getData(`/order-action-tasks/`, {}, params);
}
async function moveToNextActions(data = {}) {
  return BackendService.postData(`/order-action-tasks/batch/`, data);
}

const OrderActionService = {
  getOrderActions,
  createOrderAction,
  updateOrderAction,
  getOrderActionTasks,
  moveToNextActions,
};

export default OrderActionService;
