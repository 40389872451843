import React, { useCallback, useContext, useEffect, useState } from 'react';
import { Button, Tooltip } from '@material-ui/core';
import styles from './styles.module.css';
import DataTable from '../../utils/DataTable';
import TableFormDialog from '../../popups/TableFormDialog';
import TableService from '../../../services/TableService';
import NoteAddIcon from '@material-ui/icons/NoteAdd';
import Loader from '../../utils/Loading';
import SearchIcon from '@material-ui/icons/Search';
import Info from '../../utils/Alert/Info';
import Error from '../../utils/Alert/Error';
import Success from '../../utils/Alert/Success';
import withConsoleBase from '../../utils/ConsoleBase/withConsoleBase';
import RestrictionInfo from '../../utils/Alert/RestrictionInfo';
import AlertHelper from '../../../helpers/AlertHelper';
import { ShopContext } from '../../../Context/ShopContext';
import ConfirmPopup from '../../utils/Alert/ConfirmPopup';
import { UserContext } from '../../../Context/UserContext';
import { SUBSCRIPTION_TYPE, USER_PERMISSIONS } from '../../../const';
import TableEditDialog from '../../popups/EditTableDialog';

function Tables() {
  const { shop } = useContext(ShopContext);
  const { user } = useContext(UserContext);

  const [tables, setTables] = useState(null);
  const [searchText, setSearchText] = useState('');
  const [isLoading, setLoading] = useState(false);
  const [selectedForDelete, setSelectedForDelete] = useState(null);
  const [showAddForm, setShowAddForm] = useState(false);
  const [errorMsg, setErrorMsg] = useState();
  const [successMsg, setSuccessMsg] = useState();
  const [selectedForEdit, setSelectedForEdit] = useState(null);
  const [tablesFiltered, setTablesFiltered] = useState(null);

  const subscriptionType = shop?.subscription?.type;
  const userHasSalesPersonWritePermission = user?.shop_permissions?.sales_persons_permission === USER_PERMISSIONS.WRITE;

  const handleDeleteClick = (tables) => setSelectedForDelete(tables);
  const handleAddClick = () => setShowAddForm(true);
  const handleEditClick = (tables) => setSelectedForEdit(tables);

  const handleDeleteTable = async () => {
    setLoading(true);
    try {
      await TableService.deleteTables(selectedForDelete.id);
      setTables(tables.filter((table) => table.id !== selectedForDelete.id));
      AlertHelper.setShortMessage(setSuccessMsg, 'Table deleted successfully');
    } catch (err) {
      AlertHelper.setShortMessage(setErrorMsg, err.message);
    }
    setLoading(false);
  };

  const handleSearch = (value) => {
    setSearchText(value);
    const filterResponse = tables
      ? tables.filter(
          (table) => table.name.toLowerCase().includes(value.toLowerCase()) || table.id.toString().includes(value)
        )
      : [];
    setTablesFiltered(filterResponse);
  };

  const getTables = useCallback(async () => {
    setLoading(true);
    try {
      const table = await TableService.getTables();
      setTables(table);
    } catch (error) {
      AlertHelper.setShortMessage(setErrorMsg, error.message);
    }
    setLoading(false);
  }, []);

  const headerData = [
    {
      label: 'ID',
      id: 'id',
      type: 'text',
    },
    {
      label: 'Name',
      id: 'name',
      type: 'text',
    },
  ];

  if (userHasSalesPersonWritePermission) {
    headerData.push({
      label: 'Edit',
      id: 'updateTable',
      type: 'button',
      title: 'Edit',
      align: 'right',
      clickHandler: handleEditClick,
    });
    headerData.push({
      label: 'Delete',
      id: 'deleteTable',
      type: 'button',
      title: 'Delete',
      align: 'right',
      clickHandler: handleDeleteClick,
    });
  }

  const featureIsExpired = () => {
    const currentDate = new Date();
    const validityDate = new Date(shop.kot_validity);
    return shop.kot_validity == null || currentDate > validityDate;
  };

  useEffect(() => {
    if (!shop?.id || featureIsExpired()) {
      return;
    }
    getTables();
    //eslint-disable-next-line
  }, [getTables, shop?.id, shop?.kot_validity]);

  useEffect(() => {
    handleSearch(searchText);
    //eslint-disable-next-line
  }, [tables]);

  return (
    <div className={styles.contentWrapper}>
      <Loader isOpen={isLoading} />
      <div className={styles.titleSec}>
        <h2 className={styles.title}>
          Table <span className={styles.menuTitle}>Management</span>
        </h2>
      </div>
      {shop && featureIsExpired() ? (
        <RestrictionInfo
          title={'KOT Feature not available '}
          content={'To get KOT support options, contact support team.'}
        />
      ) : (
        <>
          <div className={styles.changeable}>
            <div className={styles.filterSec}>
              <h2 className={styles.subTitle}>Tables</h2>

              <div className={styles.filerInputSec}>
                {userHasSalesPersonWritePermission && (
                  <Button
                    variant="contained"
                    color="primary"
                    size="small"
                    style={{ backgroundColor: '#ff851b' }}
                    className={styles.actionBtn}
                    onClick={handleAddClick}
                  >
                    <NoteAddIcon className={styles.actionBtnIcon} />
                    New Table
                  </Button>
                )}
                <div className={styles.searchSec}>
                  <input
                    type="text"
                    value={searchText}
                    onChange={(e) => {
                      handleSearch(e.target.value);
                    }}
                    className={styles.searchInput}
                    placeholder="search items"
                  />
                  <Tooltip title="Search" placement="bottom">
                    <SearchIcon className={styles.searchIcon} />
                  </Tooltip>
                </div>
              </div>
            </div>
          </div>
          {errorMsg && (
            <div className={styles.marginTop}>
              <Error title={errorMsg} />
            </div>
          )}
          {successMsg && (
            <div className={styles.marginTop}>
              <Success title={successMsg} />
            </div>
          )}
          {subscriptionType &&
            shop.kot_validity !== null &&
            subscriptionType !== SUBSCRIPTION_TYPE.BASIC &&
            tables &&
            (tables.length ? (
              <DataTable columns={headerData} rows={tablesFiltered ? tablesFiltered : tables} />
            ) : (
              <Info
                title={'No tables to list'}
                content={
                  'You have no table to list with current filter configuration. Please clear the filters or create a new table'
                }
              />
            ))}
          {showAddForm && (
            <TableFormDialog
              handleClose={() => setShowAddForm(false)}
              handleSuccess={(newTable) => {
                setShowAddForm(false);
                setTables([...tables, newTable]);
              }}
              setErrorMsg={setErrorMsg}
              setSuccessMsg={setSuccessMsg}
            />
          )}
          {selectedForDelete && (
            <ConfirmPopup
              data={selectedForDelete}
              handleClose={() => setSelectedForDelete(null)}
              handleOkay={handleDeleteTable}
              alertTitle={'Confirm delete'}
              successBtnName={'delete'}
              alertContent={"Deleted table can't be restored, do you want to delete the table ?"}
            />
          )}
          {selectedForEdit && (
            <TableEditDialog
              table={selectedForEdit}
              handleClose={() => {
                setSelectedForEdit(null);
              }}
              handleSuccess={(updatedTable) => {
                setSelectedForEdit(null);
                const _tables = tables.map((table) => (table.id === selectedForEdit.id ? updatedTable : table));
                setTables(_tables);
              }}
              setErrorMsg={setErrorMsg}
              setSuccessMsg={setSuccessMsg}
            />
          )}
        </>
      )}
    </div>
  );
}

export default withConsoleBase(Tables);
